.BannerImage {
    height: auto;
    width: 100%;
    max-height: 100%;
    /* object-fit: cover; */
    
}

.myCarousel{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 600px;
  }


  @media screen and (max-width:1440px) {
    .bannerMain{
        margin-top: 55px!important;
    }
}
 