.sminingHeaderWeb {
    height: 80px;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 11;
    font-family: "PT Sans", sans-serif !important;
    background: white;
}

.smilingCartRemoveBtn {
    height: 40px;
    width: 100px;
    border: none;
    background-color: lightgray;
    font-weight: 500;
}

.YourCartMainRemkarBoxSingle {
    height: 40px;
    width: 240px;
    resize: none;
}

.SmilingUpdateQuantityBtn {
    height: 35px;
    width: 60px;
    background-color: #e1e1e1;
    border: none;
    outline: none;
    font-weight: 500;
}

.SmilingAddSingleRemkarBtn {
    height: 35px;
    width: 70px;
    background-color: #e1e1e1;
    border: none;
    font-size: 14px;
    outline: none;
    font-weight: 500;
}

/* .SmilingAddSingleRemkarBtn:hover{
    background-color: white;
    border: 1px solid #f1d8c6;
} */
.SmilingAddRemkarBtn {
    height: 40px;
    width: 130px;
    border-radius: 5px;
    font-size: 14px;
    background-color: #e1e1e1;
    border: none;
    outline: none;
    font-weight: 500;
}

/* .SmilingAddRemkarBtn:hover {
    background-color: white;
    border: 1px solid #a7a7a7;
    color: #a7a7a7;
} */

.SmilingCustomzeSaveBtn {
    height: 35px;
    width: 100px;
    background-color: #a7a7a7;
    color: white;
    border: none;
    border-radius: 6px;
    outline: none;
    font-weight: 500;
    margin-bottom: 20px;
}

.SmilingCustomzeSaveBtn:hover {
    background-color: white;
    color: #a7a7a7;
    border: 1px solid #a7a7a7;
}

.addRemkarMain {
    display: flex;
    justify-content: flex-end;
    margin-inline: 15px;
}

.YourCartMainRemkarBox {
    width: 97%;
    margin-inline: 10px;
    border-radius: 5px;
}

.Smining-Top-Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    z-index: 1;
}

.Smining-Top-LoginHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    z-index: 1;
}

.similingCartBotttomMain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px 10px 10px;
}

.allCartdataMain {
    padding-bottom: 20%;
    margin-top: 30%;
    padding-inline: 2%;
}

.Smining-Top-Header-fixed {
    display: flex;
    background-color: transparent !important;
    top: 0px;
    padding: 0px;
    z-index: 1;
    height: 30px;
    margin-inline: 6%;
}

.nav-ul-shop {
    list-style: none;
    display: flex;
    /* align-items: center; */
    gap: 30px;
    text-transform: uppercase;
    color: black;
    font-size: 13px;
    margin-left: -2em;
    margin-top: 15px;
}

.nav-ul-fixed {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 28px;
    text-transform: uppercase;
    color: #7d7f85;
    font-size: 13px;
    margin-left: -2em;
}

.nav-li-shop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 57px;
    cursor: pointer;
}

.nav-li-shop-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;

}

/* .shop-dropdown {
    height: auto;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8) !important;
    top: 72px;
    left: 0;
    backdrop-filter: blur(10px);
    transition: backdrop-filter 0.3s ease;
} */

.menuDropdownData {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    color: black !important;
    background: transparent;
    gap: 50px;
}

.shop-dropdown {
    height: auto;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 72px;
    left: 0;
    backdrop-filter: blur(8px);
    border-radius: 0 0 15px 15px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: backdrop-filter 0.3s ease;
    z-index: 11111111;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px;
}

.nav-li-shop-main:hover {
    height: 150px;
}

.shop-dropdown {
    display: none;
    transition: all 0.5s ease;
}

.shop-dropdown.open {
    display: block;
    position: absolute;
    z-index: 111111;
    width: 100%;
    margin-top: 0px;
    transition: all 0.5s ease;

}

.nav-li-smining {
    /* font-weight: 500; */
    font-size: 14px !important;
    text-transform: capitalize;
    color: #AF8238 !important;
}

.nav-li-smining:hover {
    /* font-weight: 600;
    transform: scale(1.1);
    transition: transform 0.4s ease; */
    text-decoration: underline;
}


.nav-li {
    font-weight: 500;
}

.placeOrderBtn {
    height: 50px;
    width: 40%;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: 500;
}

.placeOrderBtnMain {
    position: fixed;
    bottom: 0;
    width: 100%;
}


.Smining-Top-Header-fixed-main {
    width: 100%;
    background-color: white;
    padding: 20px 0;
    text-align: center;
    transform: translateY(-200px);
    /* transition: all 0.1s ease; */
    height: 60px;
}

.Smining-Top-Header-fixed-main.fixed {
    position: fixed;
    top: 0;
    /* box-shadow: 0 2px 5px rgba(20, 19, 19, 0.1); */
    z-index: 1000;
    transform: translateY(0px);
    transition: all 0.4s ease;
    height: 60px;
}

.shop-dropdown.open.fixed {
    position: fixed;
    top: 72px;
    box-shadow: 0 2px 5px rgba(20, 19, 19, 0.1);
    z-index: 1000;
    transform: translateY(0px);
    transition: all 0.4s ease;
    height: auto;
}



.smiling-cartBoxMain {
    border: 1px solid lightgray;
    border-radius: 10px;
    overflow: hidden;
    margin-block: 10px;
}

.smiling-cartBoxMainImageView {
    border: 1px solid lightgray;
    /* border-radius: 10px; */
    margin: 10px;
    width: fit-content;
}

.smiling-cartBoxImg {
    width: 70%;
    margin: 0px;
}

.nav-li-smining-fixed {
    font-size: 18px;
    /* font-weight: 500; */
    margin: 0px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 22px;
}

.nav-li-smining-fixed::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    height: 1px;
    background-color: black;
    transition: width 0.3s ease-out;
}

.nav-li-smining-fixed:hover::before {
    width: 100%;
    left: 0;
    right: auto;
    transition: width 0.3s ease-out;
}

.nav-li-smining {
    font-size: 16px;
    /* font-weight: 600; */
    margin: 0px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 22px;
}

/* .nav-li-smining::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    height: 1px;
    background-color: white;
    transition: width 0.3s ease-out;
} */

/* .nav-li-smining:hover::before {
    width: 100%;
    left: 0;
    right: auto;
    transition: width 0.3s ease-out;
} */

.mobileHeader {
    background-color: white;
    height: 45px;
    display: flex;
    justify-content: space-between;
}

.headerDrawerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    font-weight: 500;
    height: 40px;
    margin-inline: 5px;
}

.my-HeaderDrawer {
    list-style: none;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    margin: 5px;
}

.my-HeaderDrawer.open {
    max-height: 300px;
    margin-inline: 20px;
}

.INRitems {

    height: 45px;
    width: 50px;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    margin: 5px;
}

.INRname {
    margin: 0;
    align-self: center;
}


.smlingSearchoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: rgba(0, 0, 0, 0.40);
    display: flex;
    z-index: 999;
}

.smlingDraweOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: white;
    z-index: 999;
    transition: all 0.9s ease-in-out;
}

.smlingDraweOverlayMain {
    padding-inline: 30px;
    padding-top: 20px;
    height: 60%;
    overflow: scroll;
}

.drawrTitle {
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid white;
    margin: 0px;
    padding-top: 20px;
}

.drawrTitlediv {
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid white;
    margin: 0px;
}

.mobileSideBarSearch::placeholder {
    color: white;
}



.my-list-fineJewe {
    list-style: none;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease;
    margin: 0px;
}

.my-list-fineJewe.open {
    max-height: 400px;
    overflow: scroll;
    margin: 0;
}


.smlingTopSerachOver {
    height: 70px;
    background-color: white;
    width: 100%;
    margin: 10px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serachinputBoxOverly {
    width: 95%;
    height: 50px;
    border: none;
    outline: none;
}

.smlingTopSerachOver-Fixed {
    height: 60px;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smlingSearchoverlayNew {
    position: relative;
    transform: translateY(-200px);
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    display: flex;
    z-index: 999;
}

.smlingSearchoverlayNew.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateY(0px);
    transition: all 0.4s ease;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.40);
    display: flex;
    transform: translateY(0px);
    transition: all 0.4s ease;
}

.Smining-Top-Header-fixed-main.fixed.searchoverly {
    z-index: -500 !important;
}

.CartPageShipingIn {
    color: #7d7f85;
}

.CartPageShipingInSmall {
    display: none;
}

.level0Menu {
    transition: 'opacity .25s ease-in-out';
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}

.level1Menu {
    transition: 'opacity .25s ease-in-out';
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
}

.level2Menu {
    transition: 'opacity .25s ease-in-out';
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
}

.level0Menu:hover {
    opacity: .6;
}

.level1Menu:active {
    opacity: .6;
    text-decoration: underline;
    text-underline-offset: 2;
}

.level2Menu:hover {
    opacity: .6;
}

.smilingMobileCartDeatil {
    margin: 10px 70px 10px 10px;
}

.menuLabel {
    text-transform: lowercase;
}

.menuLabel::first-letter {
    text-transform: uppercase;
}

.smilingNoDataCart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40% !important;
}

.menuImages {
    height: 250px;
    width: 300px;
    object-fit: cover;
}

.logoImage1 {
    width: 50%;
}

.menu-list {
    display: flex;
    flex-direction: column;
    padding-right: 50px !important;
}

.nav-li-sminings {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
}

.muilistMenutext span {
    color: #7d7f85 !important;
    font-weight: 600 !important;
}

.muilistSubMenutext span {
    color: #7d7f85 !important;
    font-weight: 400px !important;
}

.muilist2ndSubMenutext span {
    color: #7d7f85 !important;
}

.HeaderMenuItemMainDiv {
    width: 70%;
    display: flex;
    align-items: center;
    height: 100% !important;
    gap: 20px;
}

.drawermenuImage {
    width: 45% !important;
}

.level2menuData {
    color: #7d7f85 !important;
}

.level2menuData:hover {
    color: black !important
}

@media (max-width:1640px) {
    .menuImages {
        height: 200px;
        width: 180px;
        object-fit: cover;
    }
}

@media screen and (min-width:1441px) {
    .mobileHeaderSmining {
        display: none;
    }

}


@media (min-width : 900px) and (max-width: 1450px) {
    .mobileViewFirstDiv2 {
        margin-left: 29px;
    }
}


@media screen and (max-width:1440px) {

    .Smining-Top-Header-fixed {
        margin-inline: 10px !important;
    }

    .mobileHeaderSmining {
        display: block !important;
        background-color: #e1e1e1 !important;
        height: 65px !important;
    }

    .placeOrderBtn {
        width: 100% !important;
    }

    .sminingHeaderWeb {
        display: none;
    }

    .top_header {
        display: none;
    }

    .middel-header-container {
        display: none;
    }

    .nav-bottom-header {
        display: none;
    }

    .smiling-cartBoxImg {
        width: 60%;
    }

    .mobileHeaderFixedMobileLastDiv {
        width: 45% !important;
        display: flex !important;
        justify-content: flex-end !important;
    }

    .cartDrawerMainMobile {
        width: 100% !important;
        max-width: 100% !important;
    }

    .mobileViewFirstDiv1 {
        width: 20% !important;
    }

    .mobileViewFirstDiv2 {
        width: 40% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .mobileViewFirstDiv3 {
        width: 25% !important;
    }

    .mobileViewSmilingTop4Icone {
        font-size: 22px !important;
    }

    .mobileViewSmilingTop1Icone {
        font-size: 22px;
    }

    .mobileViewSmilingTop2Icone {
        font-size: 22px;
    }

    .mobileViewSmilingTop3Icone {
        font-size: 22px;
        margin-top: -2px !important;
    }
}


@media screen and (max-width:1300px) {
    .css-i8mxf8-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
        width: 80% !important;
        max-width: 80% !important;
    }

}

@media (min-width: 1200px) and (max-width: 1440px) {
    .mobileViewFirstDiv2 {
        margin-top: -5px;
    }
}

@media (min-width: 768px) and (max-width: 1440px) {

    .MainlogogMobileImage {
        width: 45% !important;
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 850px) {

    .cartDrawerMainMobile .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
        min-width: 100% !important;
    }

    .css-i8mxf8-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
        width: 100% !important;
        max-width: 100% !important;
    }

    .mobileHeaderFixedMobileLastDiv {
        display: flex !important;
        justify-content: flex-end !important;
        width: 45% !important;
    }

    .smlingTopSerachOver {
        margin: 0px !important;
    }

    .drawrTitle {
        font-size: 12px !important;
        letter-spacing: 0.5px;
    }

    .drawrTitlem {
        font-size: 12px !important;
        letter-spacing: 0.5px;
    }

    .mobileViewFirstDiv2 {
        width: 47% !important;
    }

}



@media (min-width: 600px) and (max-width: 769px) {
    .MainlogogMobileImage {
        width: 50% !important;
        margin-top: -11px;
        margin-left: 0px !important;
    }
}

@media screen and (max-width:599px) {

    .smilingNoDataCart {
        margin-top: 300px !important;
    }

    .mainRenarkConatiner{
        width: 100%;
    }
    .smiling-cartBoxImg {
        width: 100% !important;
        margin: 0px !important;
    }

    .placeOrderBtn {
        width: 100% !important;
    }

    .CartPageShipingIn {
        display: none;
    }

    .CartPageShipingInSmall {
        color: #7d7f85;
        display: block;
    }

    .similingCartBotttomMain {
        display: flex;
        flex-direction: column;
    }

    .smilingCartMobileMain {
        display: flex;
        flex-direction: column;
    }

    .smilingMobileCartDeatil {
        margin: 0px !important;
    }

    .smilingCartBox1 {
        width: 90% !important;
    }

    .YourCartMainRemkarBoxSingle {
        width: 70% !important;
    }

    .SmilingAddSingleRemkarBtn {
        width: 50% !important;
    }

    .smilingAddresingleMobileMain {
        margin-top: 10px !important;
        margin-right: 0px !important;
        margin-left: 0px !important;

    }

    .simlingQualityBox {
        width: 70% !important;
    }

    .SmilingUpdateQuantityBtn {
        width: 50% !important;
    }

    .smilingQualityMain {
        width: 95% !important;
    }

    .Smining-Top-Header-fixed {
        margin-inline: 0px !important;
    }

    .smilingHeaderWhishlistIcon {
        display: none !important;
    }

    .mobileViewSmilingTop1Icone {
        display: none !important;
    }


    .PiStarThinTopIcone {
        display: none !important;
    }

    .IoSearchOutlineTopIcone {
        display: none !important;
    }

    .centerSVGMobileImage {
        height: 80px !important;
        width: 120% !important;
    }

    .MainlogogMobileImage {
        width: 45% !important;
        margin-top: -12px;
    }

    .mobileViewSmilingTop2Icone {
        height: 25px !important;
        width: 25px !important;
    }

    .mobileViewSmilingTop3Icone {
        height: 25px !important;
        width: 25px !important;
        margin-top: -5px !important;
    }

    .mobileViewFirstDiv3Sub {
        margin: 20px 10px !important;
    }

    .mobileViewFirstDiv3Sub-sub {
        gap: 8px !important;
    }

    .mobileViewFirstDiv2 {
        width: 60% !important;
    }
}

@media screen and (max-width: 400px) {
    .MainlogogMobileImage {
        width: 50% !important;
        /* margin-top: -11px */
        margin-left: 0px !important;
    }

    .mobileHeaderSmining {
        height: 60px !important;
    }

    .searchiconInMobile {
        display: none;
    }

    /* .mobileHeaderSmining {
        position: sticky;
        top: 0;
        height: 70px !important;
    } */


    .mobileViewFirstDiv {
        width: 20% !important;
    }

    .muIconeMobileHeader {
        font-size: 30px !important;
    }



    .mobileViewSmilingTop1Icone {
        height: 18px !important;
        width: 18px !important;
    }




    .mobileViewSmilingTop3Icone {
        height: 25px !important;
        width: 25px !important;
    }



}

.css-19kzrtu {
    padding: 0px !important;
}

.smilingCartTabMain .MuiTabs-scroller {
    display: flex;
    justify-content: center;
}

.smilingCartTabMain .css-1wf8b0h-MuiTabs-flexContainer {
    /* width: 50%; */
    background-color: #e1e1e1c2;
    border-radius: 10px !important;
}

.smilingCartTabMain .MuiTabs-indicator {
    background: unset;
}

.smilingCartTab1Main {
    width: 50%;
}



.smilingCartTabMain .MuiTab-textColorPrimary.Mui-selected {
    background-color: #ccc;
    color: black;
    border-radius: 10px;
}

.smilingCartTab1Main {
    min-width: max-content !important;
    width: max-content !important;

}

.smilingCartTab1Main .MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary .Mui-selected {
    width: 100%;
}