.smiling-FaqMain {
    margin-inline: 6%;
    background-color: white;
}

.smiling-FaqSubMain {
    padding-inline: 32%;
}

.smiling-FaqMainTitle {
    color: #7d7f85;
    font-family: 'FreightDispProBook-Regular,Times New Roman,serif';
    font-size: 40px;
}

.smiling-FaqMainSubTitle {
    color: #7d7f85;
    font-family: 'TTCommons-Regular,Helvetica,sans-serif';
    font-size: 25px;
}

.smiling-FaqMainDesc {
    color: #7d7f85;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
}


@media screen and (max-width:1200px) {
    .smiling-FaqMain {
    margin-inline: 2%;

    }
    .smiling-FaqSubMain{
    padding-inline: 5%;

    }
}