.prodetailbtn {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    background-color: #7d7f85;
    outline: none;
    border: none;
    letter-spacing: 2px;
    padding: 20px 0px;
    border: 1px solid #7d7f85;
    transition: 0.3s ease;
    /* margin-inline: 20px; */
    font-size: 13px;
    width: 100%;
}

.prodetailbtn:hover {
    background-color: white;
    color: #7d7f85;
}

.tellmoreli {
    font-size: 13px;
    color: #7f7d85;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 35px;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 0px;
}

.tellmorep {
    display: flex;
    justify-content: space-between;
    width: 100%
}


.my-list-fineJewe {
    list-style: none;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease;
    margin: 0px;
}

.my-list-fineJewe.openAcc {
    max-height: 400px;
    margin: 0;
}

.srAccContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.srFloat {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.sracctabletd1 {
    text-align: center;
    width: 44.4955%;
    display: table-cell;
    border-left: 1px solid #e1e1e1;
    padding: 0.935em 1.875em;
    white-space: nowrap;
}

.sracctabletd2 {
    text-align: center;
    width: 26.5045%;
    display: table-cell;
    border-left: 1px solid #e1e1e1;
    padding: 0.935em 1.875em;
    white-space: nowrap;
}

.sracctabletd3 {
    text-align: center;
    width: 24%;
    display: table-cell;
    border-left: 1px solid #e1e1e1;
    padding: 0.935em 1.875em;
    white-space: nowrap;
}

.sracctable {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #e1e1e1;
    display: table;
    width: 100%;
    margin-bottom: 1em;
}

.compeletethelook_cont {
    padding: 0px 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.compeletethelook_prodt {
    z-index: 1;
    position: relative;
    margin-left: -140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ctl_img {
    object-fit: cover;
    width: 63%;
    margin-top: 12px;
    margin-bottom: -100px;
}

.completethelook_outer {
    padding: 5px;
    border: 1px solid #e1e1e1;
    background-color: #fff;
}

.product-detail-container {
    display: flex;
    width: 100%;
    max-height: 1009px;
}

.srprodetail1 {
    width: 50%
}

.srprodetail2 {
    width: 50%;
    display: flex;
    justify-content: center;
}

.srprodetail2-cont {
    margin: 50px 150px 0px;
    width: 50%;
}

.srthumb_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 50px;
    /* z-index: 1; */
    top: 200px;
    position: absolute;
}

.prodDetailWhitecont {
    width: 100%;
}

.srthumb_images_el {
    width: 58px;
    height: 58px;
    border: 1px solid #e1e1e1;
}

.srthelook_img {
    width: 110px;
    height: 110px;
    object-fit: cover;
}

.srthelook_prodinfo {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    gap: 158px;
    margin-right: 30px;
}

.srAccul {
    list-style-type: none;
    width: 40%;
}

.Acc-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-top:80px; */
    /* margin-bottom: -60px;
    margin-top: 7%; */
}

.smilingProdutDetltTitle {
    text-align: start !important;
    font-size: 40px;
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #424242;
}

.menuItemTimeEleveDeatil {
    font-size: 15px;
    color: rgb(66, 66, 66);
    font-family: "PT Sans", sans-serif;
}

.menuitemSelectoreMain {
    border: none;
    outline: none;
    font-size: 13px;
    color: rgb(66, 66, 66);
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    letter-spacing: 2.2px;
}

.mainpriceDeatilPage {
    font-weight: bold;
    font-size: 22px;
    display: flex;
    letter-spacing: 2.2px;
    font-family: "PT Sans", sans-serif;
}

.addtocartcont {
    border: 1px solid #d2815f;
    width: 80%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 49px;
    cursor: pointer;
}

.addtocartcont:hover {
    background-color: #d2815f0d;
}

.addtocarttxt {
    color: #d2815f;
    font-family: "PT Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1.5px;
    position: relative;
}


.wishlistcont {
    border: 1px solid #d2815f;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4%;
    border-radius: 2px;
}

.wishlistcont:hover {
    background-color: #d2815f0d;
}

.proDeatilList {
    width: 25%;
    font-weight: 600;
}

.proDeatilList1 {
    width: 25%;
    color: gray;
    font-size: 14px;
}

.proDeatilList2 {
    width: 25%;
    color: gray;
    font-size: 14px;
}

.proDeatilList3 {
    font-size: 14px;
    width: 25%;
    color: gray;

}

.proDeatilList4 {
    color: gray;
    font-size: 14px;
    width: 25%;
}

.proDeatilList1.one {
    font-weight: 600;
}

.proDeatilList2.one {
    font-weight: 600;

}

.proDeatilList3.one {
    font-weight: 600;

}

.proDeatilList4.one {
    font-weight: 600;

}

@media screen and (max-width:1731px) {
    .prodDetailWhitecont {
        background-color: white;
        width: 100%;
    }
}

@media screen and (max-width:1665px) {
    .compeletethelook_cont {
        padding: 0px 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .compeletethelook_prodt {

        margin-left: 0px;

    }

    .smilingCompleteLookMainWeb {
        margin-inline: 2% !important;
    }
}

@media screen and (min-width:1460px) {
    .smilingCompleteLookMainMobile {
        display: none !important;
    }
}

@media screen and (max-width:1461px) {

    .smilingCompleteLookMainWeb {
        display: none !important;
    }

    .smilingCompleteLookMainMobileImg {
        width: 95% !important;
    }

    .ctl_img {
        object-fit: cover;
        width: 63%;
        margin-top: 12px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width:1000px) {
    .ctl_img {
        object-fit: cover;
        width: 100%;
        margin-top: 12px;
        margin-bottom: 0px;
        margin: 0px 20px;
    }

    .product-detail-container {
        max-height: fit-content !important;
    }
}

@media screen and (max-width:1440px) {
    .srthumb_images {
        display: flex;
        justify-content: center;
        flex-direction: row;
        position: relative;
        gap: 10px;
        top: auto;
        z-index: auto;
    }

    .smilingProdutDetltTitle{
        font-size: 25px;
    }
    .srprodetail2-cont {
        margin: 120px 50px 0px;
    }

    .tellmeMoreMain {
        width: 100% !important;
    }

    .tellmeMoreMainMobileDiv {
        margin-bottom: 30px;
    }
}

@media screen and (max-width:1040px) {
    .srAccul {
        list-style-type: none;
        width: 100%;
        padding: 5px;
    }
}

@media screen and (max-width:999px) {
    .product-detail-container {
        display: flex;
        flex-direction: column;
        width: 100%
    }

    .srprodetail1 {
        width: auto
    }

    .smilingDeatilPageMainImage {
        margin-left: 0px !important;
    }

    .srprodetail2 {
        width: auto;
        display: flex;
        justify-content: center;
    }

    .Acc-container {
        margin-bottom: 0px;
        margin-top: 40px;
    }

    .srprodetail2-cont {
        margin-top: 70px !important;
        width: 100% !important;
    }

    .smilingProdutDetltTitle {
        text-align: center !important;
    }
}

@media screen and (max-width:890px) {
    .srthelook_prodinfo {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        width: 100%;
        gap: 0px;

    }
}

@media screen and (min-width:700px) {
    .CustomiZationDeatilPageMobile {
        display: none !important;
    }
}

@media screen and (max-width:699px) {
    .CustomiZationDeatilPageWeb {
        display: none !important;
    }

    .srprodetail2-cont {
        margin: 10px !important;
    }

    .smilingProdutDetltTitle{
        font-size: 20px;
    }
}

@media screen and (max-width:621px) {
    .srthumb_images {
        padding-left: 0;
        width: 100%;
    }

    .compeletethelook_cont {
        padding: 0px;
    }

    .completethelook_outer {
        padding: 0;
        border: none;
    }
}

@media screen and (max-width:375px) {

    .menuitemSelectoreMain {
        font-size: 11px !important;
        font-family: "PT Sans", sans-serif !important;
        letter-spacing: 0px !important;
    }
}

@media screen and (max-width:467px) {
    .srthumb_images_el {
        width: 43px;
        height: 43px;
        border: 1px solid #e1e1e1;
    }

    .srthumb_images {
        gap: 5px;
    }


}