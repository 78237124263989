.gor-scrollable-container {
    width: 100%;
    height: 800px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* border: 1px solid #ccc; */
    padding: 10px;
}

.gor-image-scroll-content {
    display: inline-block;
    overflow: auto;
    object-fit: cover;
}

.gor-image-wrapper {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    width: 33.33%;
}

.gor-image-wrapper img {
    max-width: 100%;
    height: 700px;
    object-fit: cover;
}

.gorRecommDiv {
    position: absolute;
    right: 20px;
    bottom: 110px;
}

.gorRecommBtn {
    background-color: #ffffffe6;
    border: none;
}

@media screen and (max-width:1441px) {

    .gorReccommMain{
        margin-inline: 3%!important;
    }
}