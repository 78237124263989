body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn_white{
  font-family: Calibri;
    /* font-family: 'Lato-Regular' !important; */
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    position: relative;
    padding: 5px 7px;
    background-color: #FFF;
    border: 1px solid #C9C9C9 !important;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#E2E2E2));
    background: -webkit-linear-gradient(top, #FFF, #E2E2E2);
    background: -moz-linear-gradient(top, #FFF, #E2E2E2);
    background: -ms-linear-gradient(top, #FFF, #E2E2E2);
    background: -o-linear-gradient(top, #FFF, #E2E2E2);
    background: linear-gradient(to bottom, #FFF, #EBEBEB);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: inset 0px 1px 0px #F0F0F0, 0px 4px 0px 0px #DBDBDB, 0px 2px 3px #999;
    -moz-box-shadow: inset 0px 1px 0px #F0F0F0, 0px 4px 0px 0px #DBDBDB, 0px 2px 3px #999;
    -o-box-shadow: inset 0px 1px 0px #F0F0F0, 0px 4px 0px 0px #DBDBDB, 0px 2px 3px #999;
    box-shadow: inset 0px 1px 0px #F0F0F0, 0px 4px 0px 0px #DBDBDB, 0px 2px 3px #999;
    padding-left: 5px !important;
    border-left: 4px solid #5994BB !important;
}