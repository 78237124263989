.mainSocialMediaConatiner{
    margin-top: 20px;
  }
  /* .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  .galeryComponents{
    color: rgba(29, 50, 88, 0.8) !important;
    text-align: center;
    font-size: 22px;
  }
  .socialmediaptag{
    color: rgba(29, 50, 88, 0.8) !important;
    text-align: center;
    font-size: 22px;
    text-transform: capitalize;
    line-height: 2px;
    margin: 50px 0px 50px 0px !important;
  }
  .galeryComponents{
    color: rgba(29, 50, 88, 0.8) !important;
    text-align: center;
    font-size: 22px;
    text-transform: capitalize;
    line-height: 2px;
    margin: 50px 0px 50px 0px !important;
  }


  @media (min-width: 445px) and (max-width: 768px) {

  }

  @media (max-width: 445px) {
    
  }