
.smling-forgot-main{
    margin-inline: 6%;
    background-color: white;
}

.labGrowForgot {
    width: 420px;
    margin: 10px;
}

.cancleForgot{
    color: #7d7f85;
    font-size: 12px;
    margin-top: 25px;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
}

.cancleForgot::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.3s ease;
}
.cancleForgot:hover::before {
    width: 100%; 
}


#outlined-password-input::-ms-reveal {
    display: none !important;
}

#outlined-confirm-password-input::-ms-reveal {
    display: none !important;
}


@media screen and (max-width: 700px) {
    .labGrowForgot {
        width: 90%;
    }
    .submitBtnForgot{
        width: 80%;
    }
   
}