.topSection{
    width: 100%;
    height: 60px;
    background-color: gray;
    border: 1px double gray;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.enterEmail{
    height: 45px;
    width: 550px;
    align-self: center;
    padding: 10px;
}

.btnjoin{
    height: 40px;
    width: 100px;
    background-color: white;
    align-self: center;
    margin-left: 20px;
    border: 0;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    font-weight: 300;
}


@media screen and (min-width: 1441px) {
    .mobileFooterSection {
        display: none;
    }
}


@media screen and (max-width: 1440px) {

    .webFooterSection {
        display: none;
    }


}