.smiling-wishlist {
    margin-inline: 6%;
    background-color: white;
}

.SmiWishListTitle {
    color: #7d7f85;
    font-family: 'FreightDispProBook-Regular,Times New Roman,serif';
    font-size: 40px;
    padding-top: 30px;
    text-align: center;
    width: 100%;
}

.smilingListTopButton {
    text-align: center;
    margin-top: 10px;
}

.smiTopShareBtn {
    height: 60px;
    width: 150px;
    color: white;
    background-color: #7d7f85;
    border: none;
    font-size: 13px;
    font-weight: 500;
    margin: 10px;
    transition: all 0.4s ease;
}

.smiTopShareBtn:hover {
    background-color: white;
    color: #7d7f85;
    border: 1px solid #7d7f85;
}

.smiTopClearBtn:hover {
    background-color: rgba(66, 66, 66, 0.05);
}

.smiTopAddAllBtn {
    height: 60px;
    width: 150px;
    color: #7d7f85!important;
    background-color: white;
    border: 1px solid #7d7f85!important;
    font-size: 13px;
    font-weight: 500;
    margin: 10px!important;
    justify-content: center;
}

.smiTopAddAllBtn:hover {
    color: white;
    background-color: #7d7f85;
}

.smiWishLsitBoxMain {
    display: flex;
    margin-inline: 5%;
    margin-top: 30px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.smiWishLsitBox {
    border: 1px solid #ccc;
    width: 24.2%;
    position: relative;
    margin: 5px;
}

.smiWishLsitBoxImge {
    width: 100%;
    /* margin-top: 30px; */
    height: 350px;
}

.smiWishLsitBoxDesc1 {
    font-size: 13px;
    font-weight: 500;
    color: #212529;
    text-align: center;
    margin-bottom: 5px;
    font-family: 'PT Sans', sans-serif;

}

.smiWishLsitBoxDesc2 {
    font-size: 13px;
    font-weight: 400;
    color: #212529;
    text-align: center;
    margin: 0px;
    font-family: 'PT Sans', sans-serif;
}

.smiWishLsitBoxTitltLine {
    text-align: center;
    color: #212529;
    font-size: 14px;
    height: 30px;
    margin: 0px;
    font-family: 'PT Sans', sans-serif;

}

.smiWishLsitBoxDesc3 {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    background-color: white;
    color: black;
    border: 1px solid black;
    height: 30px;
    width: 60%;
    font-family: 'PT Sans', sans-serif;
}
.smiWishLsitBoxDesc4 {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border: 1px solid;
    height: 30px;
    width: 60%;
    font-family: 'PT Sans', sans-serif;
}

.smiWishLsitBoxDescPrice {
    font-size: 13px;
    font-weight: 500;
    color: #212529;
    text-align: center;
    margin-bottom: 5px;
    font-family: 'PT Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}



@media screen and (max-width: 1600px) {

    .smiling-wishlist {
        margin-inline: 0px !important;
    }
}

@media screen and (max-width: 1441px) {


    .smiWishLsitBoxDesc3 {
        margin-inline: 5px;
    }

    .smiWishLsitBoxDesc4 {
        margin-inline: 5px;
    }

    .smiWishLsitBoxMain {
        margin-inline: 1% !important;
    }
}

@media screen and (max-width: 1300px) {
    .smiWishLsitBox {
        width: 32% !important;
    }
}

@media screen and (max-width: 1000px) {

    .smiWishLsitBox {
        width: 33.33%;
    }

    .smiWishLsitBoxDesc1 {
        margin: 0;
    }

    .mobileFootreCs{
        top: 50%!important;
    }
}

@media screen and (max-width: 800px) {

    .smiWishLsitBox {
        width: 48% !important;
    }

    .SmiWishListTitle {
        width: auto !important;
    }

    .smiWishLsitBoxImge {
        height: 300px !important;
        object-fit: contain;
    }

    .mobilkeAddToCartBtn {
        margin-block: 10px !important;
    }

}

@media screen and (max-width: 600px) {
    .smilingListTopButton {
        display: flex;
        flex-direction: column;
        margin: 20px;
    }

    .smiTopShareBtn {
        width: 100%;
        margin: 0px;
    }

    .smiTopAddAllBtn {
        width: 100%;
        margin: 0px;
        margin-top: 15px;
    }

    .smiWishLsitBoxImge {
        height: 210px !important;
        object-fit: contain;
    }

    .smiWishLsitBox{
        width: 47%!important;
    }

    .mobilkeAddToCartBtn{
        margin-block: 5px!important;
    }

    .SmiWishListTitle {
        padding-top: 0px !important;
        margin: 5px;
        font-size: 25px;
        margin-left: 30px;
    }

}

@media screen and (max-width: 450px) {
    .smiWishLsitBoxMain {
        display: flex !important;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        margin-inline: 0% !important;
        padding: 5px;
    }


    .smiWishLsitBox {
        width: 100% !important;
        margin: 5px 0px !important;
    }

}