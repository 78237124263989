.smilingPaymentMain {
    margin-inline: 6%;
    background-color: white;
}

.smilingPayentImg {
    height: 450px;
    width: 450px;
}

.smilingPaySub1 {
    padding: 50px;
    display: flex;
}

.smilingPaySub1Box1 {
    width: 65%;
    padding: 50px;
}

.smilingPaySub1Box2 {
    width: 35%;
    padding: 50px;
}

.paymentBtn {
    height: 40px;
    width: 180px;
    background-color: #e1e1e1;
    border: 1px solid lightgray;
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 60px;
}

.AddressTitle {
    font-size: 15px;
    margin: 0px;
}

.AdressData {
    font-size: 16px;
    margin: 0px;
}

.smilingPaymentBtn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media screen and (min-width:1001px) {
    .smilingPaymentMainMobile {
        display: none;
    }

}

@media screen and (max-width: 600px) {
    .paymentSubDiv {
        padding: 20px 0px 0px!important;
    }

    .paymentBtn {
        margin-bottom: 10px;
    }

    .mainTitleMobileShiping{
        font-size: 20px!important;
    }

    .BilingAdressMain{
        margin-top: 15px!important;
    }

    .shipingName{
        font-size: 17px!important;
    }
}

@media screen and (max-width:1000px) {


    .smilingPaymentMainWeb {
        display: none;
    }
}