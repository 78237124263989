.homeMain{
    /* margin-inline: 6%; */
    background-color: white;
    font-family: "PT Sans", sans-serif !important;
}
.smining-header{
    height: 110px;
    background-color: transparent!important;
}
/* 

@media screen and (min-width: 1441px) {
    .mobileFooterSection {
        display: none;
    }
} */

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  
@media screen and (max-width: 1440px) {

    .homeMain {
        /* margin-inline: 2%; */

    }

   
    .paddingTopMobileSet{
        padding-top: 0px!important;
    }


}
