.smilingCartPageMain {
    background-color: white;
    margin-inline: 6%;
}


.smilingCartDeatilSub1 {
    display: flex;
    justify-content: center;
    margin: 2px 20px;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    padding: 70px 0px;
}

.smilingCartDeatilSub11 {
    display: flex;
    justify-content: center;
    margin: 2px 20px;
    border-radius: 12px;
    height: 100%;
    padding: 10px 0px;
}

.smilingCartDeatilSub2 {
    width: 100%;
    min-height: 400px;
}

.prodTitleLine {
    font-size: 40px;
    font-family: FreightDisp Pro Medium;
    color: #7d7f85;
    line-height: 40px;
    margin-bottom: 14px;
}


.smiling-cartPageBoxMain {
    border: 1px solid #ccc;
    position: relative;
    /* margin: 5px; */
    /* width: 47%; */
    border-radius: 5px;
    overflow: hidden;
    /* max-height: 250px; */
}

.cartImageShow {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #e1e1e1;
}


.cartPageTopBtn {
    height: 60px;
    width: 150px;
    color: #7d7f85!important;
    background-color: white;
    border: 1px solid #7d7f85!important;
    font-size: 13px;
    font-weight: 500;
    margin: 10px!important;
    justify-content: center;
}

.cartPageTopBtn:hover {
    color: white;
    background-color: #7d7f85;
}

.smilingCartPageDataMain {
    display: flex;
    flex-wrap: wrap;
}

.placeOrderCartPageBtnMain {
    display: flex;
    justify-content: center;
}

.placeOrderCartPageBtn {
    background-color: #888;
    color: white;
    border: none;
    outline: none;
    height: 45px;
    border-radius: 5px;
    width: 200px;
    font-weight: 500;
}

.similingCartPageBotttomMain {
    display: flex;
    justify-content: end !important;
    margin-top: 15px;
}

.smilingCartPageTab1Main {
    height: 60px;
    width: 150px;
    color: #7d7f85 !important;
    background-color: white !important;
    border: 1px solid #7d7f85 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    margin: 10px !important;
}

.YourCartPageMainRemkarBox {
    width: 400px;
    padding-left: 5px;
}

.smilingCartPageTab1Main:hover {
    color: white !important;
    background-color: #7d7f85 !important;
}

.smilingCartPageTab2Main {
    height: 60px;
    width: 150px;
    color: #7d7f85 !important;
    background-color: white !important;
    border: 1px solid #7d7f85 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    margin: 10px !important;
}

.smilingCartPageTab2Main:hover {
    color: white !important;
    background-color: #7d7f85 !important;
}

.smilingCartPageTab3Main {
    height: 60px;
    width: 160px;
    color: #7d7f85 !important;
    background-color: white !important;
    border: 1px solid #7d7f85 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin: 10px !important;
}

.smilingCartPageTab3Main:hover {
    color: white !important;
    background-color: #7d7f85 !important;
}

.browseBtnMore {
    height: 50px;
    width: 220px;
    border: none;
    outline: none;
    background-color: #7d7f85;
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.browseBtnMore:hover {
    background-color: white;
    color: #7d7f85;
    border: 1px solid #7d7f85;
}

.css-1aquho2-MuiTabs-indicator {
    width: 0px !important;
}

.YourCartMainRemkarBoxSingle {
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    height: 33px;
    border-bottom: 1px solid lightgray;
}

.SmilingCustomzeSaveBtn {
    height: 35px;
    width: 100px;
    background-color: #a7a7a7;
    color: white;
    border: none;
    border-radius: 6px;
    outline: none;
    font-weight: 500;
    margin-bottom: 20px;
}

.SmilingCustomzeSaveBtn:hover {
    background-color: white;
    color: #a7a7a7;
    border: 1px solid #a7a7a7;
}

.newBtn {
    height: 60px;
    width: 150px;
    background-color: #7d7f85;
    color: white;
    border: 1px solid #7d7f85;
    font-size: 15px;
    font-weight: 500;
    margin: 10px;
}

.newBtn:hover {
    color: #7d7f85;
    background-color: white;
}

.cartProdSection .cartProdpart {
    padding: 10px 0px 10px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Four columns with equal width */
    gap: 7px;
}

.cartProdSection .cartProdpart .smiling-cartPageBoxMain {
    width: unset !important;

}

.selected {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.popUpcontainer {
    width: 100%;
    height: 70%;
    margin-top: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.mainCartContainer {
    display: flex;
    gap: 30px;
    /* margin-bottom: -150px; */
}

.resproDet {
    display: flex;
    width: 70%;
}

.resCon {
    width: 30%;
    height: 600px;
    overflow-y: scroll;
}

.designNo {
    position: absolute;
    top: 67%;
    left: 40%;
    font-size: 12px;
    color: #212529;
    font-weight: 500;
    opacity: .8;
}

.ImageViewdesignNo {
    position: relative;
    top: 5px;
    left: 5px;
    font-size: 12px;
    color: #212529;
    font-weight: 500;
    opacity: .4;
}



.mainRenarkConatiner {
    width: 550px;
    font-size: 13px;
}

.mainOrderRenarkConatiner {
    width: 443px;
    font-size: 13px;
}

.totalpriceConatiner {
    width: 443px;
}

.remarkText {
    color: #7d7f85 !important;
    text-align: start;
}

.Orderremarkbtn {
    text-align: right !important;
}

.showremarkbtn {
    background-color: #7d7f85 !important;
    color: white !important;
    border: 1px solid #7d7f85 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    margin: 10px !important;
}

.showremarkbtn:hover {
    background-color: white !important;
    color: #7d7f85 !important;
}

.cancelremarkbtn {
    color: #7d7f85 !important;
    font-weight: 500 !important;
    margin: 10px !important;
    border: 1px solid #7d7f85 !important;
}

.cancelremarkbtn:hover {
    background-color: #7d7f85 !important;
    color: white !important;
}


.listing-featuresN {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 0px 0px;
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    color: #4a5568;
}

.feature {
    display: flex;
    align-items: center;
    padding-right: 3px;
}

.feature p {
    margin: 0 0 5px 1px;
}

.feature4 p {
    margin: 2px 5px 5px 2px
}

.feature-count {
    font-weight: 500;
    color: #1f1919c7;
    opacity: .7;
}

.currencyFont {
    margin-right: 2px !important;
}


.smilingListCartTopButton {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-inline: 1%;
}

@media (max-width: 1284px) {
    .popUpcontainer {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }

    .resCon {
        width: 50%;
        height: 700px;
        overflow-y: scroll;
    }
}

@media (max-width: 1080px) {
    .resproDet {
        display: none;
    }

    .resCon {
        width: 100%;
        height: 100%;
        margin-bottom: 120px;
    }

    .cartProdSection .cartProdpart {
        grid-template-columns: repeat(3, 1fr);
    }

    /* .popUpcontainer{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }
    .resCon{
    width:50%;
    height: 700px;
    overflow-y: scroll;
    }

    .resproDet{
    display: flex;
    width:50%;
    } */
}


@media (max-width: 768px) {
    .popUpcontainer {
        width: 100%;
        height: 70%;
        margin-top: 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 20px;
        flex-direction: column;
    }



}

/* @media screen and (max-width: 1200px) {
    .cartProdSection .cartProdpart{
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (max-width: 980px) {
    .cartProdSection .cartProdpart{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 750px) {
    .cartProdSection .cartProdpart{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 530px) {
    .cartProdSection .cartProdpart{
        grid-template-columns: repeat(1, 1fr);
    }
} */

.SmiCartTitle {
    color: #7d7f85;
    font-family: 'FreightDispProBook-Regular,Times New Roman,serif';
    font-size: 40px;
    padding-top: 30px;
    text-align: center;
    width: 100%;
}

.SmilingUpdateQuantityBtn {
    color: black;
}

.simlingQualityBox {
    border-radius: 0px !important;
}

.SaveBtnCart {
    color: black;
}


@media screen and (max-width: 18200px) {
    .cartProdSection .cartProdpart {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1600px) {

    .smilingCartPageMain {
        margin-inline: 0% !important;
    }
}

@media screen and (min-width: 600px) {
    .placeOrderCartPageBtnMobile {
        display: none !important;
    }
}


@media screen and (max-width: 1450px) {

    .cartImageShow {
        height: 220px !important;
        width: 100% !important;
    }

    .listing-featuresN {
        padding: 0px;
        font-size: 12px;
    }



    .feature p {
        margin: 0 0 5px 1px !important;
    }

    .cartCustomizationMainMobile {
        width: 400px !important;
    }

    .mainRenarkConatiner {
        width: 400px !important;
    }

    .resCon {
        width: 45% !important;
    }

    .mainCartContainer {
        gap: 5px !important;
        margin-top: 5px;
    }

    .smilingCartDeatilSub1 {
        padding: 0px !important;
        width: 100%;
    }



    .smilingCartPagePlaceOrderBtnMainWeb {
        display: none !important;
    }

    .placeOrderCartPageBtnMobile {
        background-color: #888;
        color: white;
        height: 60px;
        width: 150px;
        border: none;
        font-size: 13px;
        cursor: pointer;
        font-weight: 500;
        font-family: 'PT Sans', sans-serif;
        margin: 10px;
    }

    .smilingCartPageDataMain {
        display: flex !important;
        flex-wrap: nowrap !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
    }

    .smiling-cartPageBoxMain {
        width: 90% !important;
    }

    .similingCartPageBotttomMain {
        display: flex !important;


    }

    .placeOrderCartPageBtnMain {
        position: fixed;
        bottom: 0px;
    }

    .smilingCartPagePrice {
        margin: 10px 50px 10px 0px !important;
    }

    .mainOrderRenarkConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
    }

    .totalpriceConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
        padding-top: 10px;
    }
}

@media (min-width : 1070px) and (max-width: 1290px) {

    .CartPageMainImageShowMobile {
        height: 680px !important;
    }
}

@media screen and (max-width: 1070px) {


    .resCon {
        width: 70% !important;
    }

    .mainCartContainer {
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 599px) {
    .smilingListCartTopButtonMobile {
        display: none !important;
    }

    .backErrorMobile {
        display: none !important;
    }

    .ClreareAllMobilee {
        display: none !important;
    }
}

/* .mobileFootreCs {
    position: absolute;
    bottom: 0px;
    width: 100%;
} */

@media screen and (max-width: 600px) {

    .smilingListCartTopButton {
        display: none !important;
    }

    .resCon {
        width: 100% !important;
    }

    .SmiCartTitle {
        padding-top: 0px !important;
    }

    .placeOrderCartPageBtnMobile {
        width: 100%;
        margin-top: 10px;
        height: 50px !important;
        position: fixed;
        bottom: 0px;
        margin: 0px !important;
        z-index: 1111111111111;
    }

    .cartPageTopBtn {
        width: 100%;
        margin: 0px;
        margin-top: 15px;
    }

    .YourCartPageMainRemkarBox {
        width: 100% !important;
    }

    .similingCartPageBotttomMain {
        margin: 0px !important;
        padding: 10px 0px !important;
    }

    .feature {
        font-size: 12px !important;
    }

    .smilingCartPageMain {
        margin-inline: 0%;
    }

    .cartPageMobileSet {
        padding-inline: 2px !important;
    }

    .cartProdSection .cartProdpart {
        gap: 5px !important;
    }

    .smilingListCartTopButton {
        margin: 5px;
    }

    .mobileFootreCs {
        margin-bottom: 25px;
    }

    .smilingListCartTopButton {
        display: flex;
        flex-direction: column;
        margin: 20px;
    }

    .SmiCartTitle {
        font-size: 25px;
        padding: 5px;
        margin: 0px;
    }


}



@media screen and (max-width: 800px) {

    .similingCartPageBotttomMain {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-bottom: 50px !important;
    }

    .smilingQualityMain {
        margin-block: 10px !important;
        width: 50% !important;
        margin-right: 0px !important;
    }

    .smilingAddresingleMobileMain {
        margin-left: 0px !important;
    }

    .smilingAddresingleMobileMain {
        width: 100% !important;
    }

    .SmiCartTitle {
        width: 100% !important;
    }


    .YourCartMainRemkarBoxSingle {
        width: 60% !important
    }

    .SmilingAddSingleRemkarBtn {
        width: 40% !important;
    }

    .simlingQualityBox {
        width: 60% !important
    }

    .SmilingUpdateQuantityBtn {
        width: 40% !important;
    }

    .mainOrderRenarkConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
    }

    .totalpriceConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
        padding-top: 10px;
    }

    .feature {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 400px) {

    .SmilingAddSingleRemkarBtn {
        font-size: 12px !important;
    }

    .smiling-cartPageBoxImgView {
        width: 250px !important;
    }

    .YourCartMainRemkarBoxSingle {
        width: 70% !important
    }

    .mainRenarkConatiner {
        width: 100%;
    }

    .listing-featuresN {
        padding: 5px;
        font-size: 10.5px;
    }

    .mt-3 {
        width: 100%;
    }

    .mainOrderRenarkConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
    }

    .totalpriceConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
    }

    .smilingQualityMain {
        width: 50% !important;
    }

    .totalpriceConatiner {
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
        padding-top: 10px;
    }

    .totalpriceConatiner span {
        font-size: 12px !important;
    }

    .feature {
        font-size: 9px !important;
    }

    .feature p {
        padding: 0 !important;
        margin: 0 !important;
    }

    .cartProdSection .cartProdpart {
        padding: 8px 2px 8px 2px;
    }

}