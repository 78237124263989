
.gorInstaMain{
    width: 100%;
    display: flex;
    padding-inline: 15%;
    flex-wrap: wrap;
}
.groInstaImagesMain{
    width: 25%;
    overflow: hidden; 
    position: relative; 
    padding: 7px;

}
.groInstaImages{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.gorInstaTitle{
    font-size: 30px;
    padding-inline: 15%;
    margin: 0px;
    font-family: 'Freight Big Pro,serif';
}
.gorInstaIconeMain{
    position: absolute;
    right: 25px;
    bottom: 20px;
}

.gorInstagramPopup{
    width: 1100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    padding-top: 2;
    prefix: 4;
    padding-bottom: 3;
}
.gorinstapopupImg{
    width: 100%;
    height: 800px;
    object-fit: cover;
}
.gorinstapopupMain{
    display: flex;
}
.gorInstagramPopupImage{
    width: 60%;
}
.gorInstagramPopupDesc{
    width: 40%;
}
.gorInstaSliderImageMain{
    display: flex!important;

}
.gorInstaSliderImageBox{
    width: 33.33%;
    border: 1px solid;
    margin-inline: 5px;
    border-radius: 5px;
}
.gorInstaSliderImage{
    height: 100%;
    width: 100%;
    padding: 7px;
}

.custom-modal {
    border: none !important; 
}

.custom-modal .gorInstagramPopup {
    border: none !important;
}
@media screen and (max-width:1441px) {
    .gorInstaMain{
        padding-inline: 7%;
    }
}

@media screen and (max-width:1200px) {
    .gorInstaMain{
        padding-inline: 3%;
    }

    .groInstaImagesMain{
        width: 33.33%;
    }
}

@media screen and (max-width: 900px) {
    .gorInstaMain{
        padding-inline: 3%;
    }

    .groInstaImagesMain{
        width: 50%;
    }
}