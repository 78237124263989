.gorTermsMain{
    margin-top: 8%;
    margin-inline: 15%;
}


@media screen and (max-width: 1440px) {
    .gorTermsMain{
        margin-top: 10%;
        margin-inline: 5%;
    }
}

@media screen and (max-width: 1100px) {
    .gorTermsMain{
        margin-top: 13%;
        margin-inline: 2%;
    }
}

@media screen and (max-width: 500px) {
    .gorTermsMain{
        margin-top: 50%!important;
        margin-inline: 2%;
    }
}

@media screen and (max-width: 900px) {
    .gorTermsMain{
        margin-top: 30%;
        margin-inline: 2%;
    }
}