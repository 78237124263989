.smiling-LabGrow-container {
    height: auto;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    position: fixed;
}

.smiling-LabGrow-container2 {
    background-color: black;
    opacity: 0.2;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    position: fixed
}

.labGrowMain {
    margin-inline: 6%;
    background-color: white;
}

.labGrowTopTitle {
    color: #7d7f85;
    font-family: 'TTCommons-Medium,Helvetica,sans-serif';
    text-align: center;
    font-size: 13px;
    margin: 5px;
    font-weight: 500;
}

.labGrowTopDesc {
    font-family: 'FreightDispProMedium-Regular,Times New Roman,serif';
    color: #7d7f85;
    text-align: center;
    font-size: 25px;
}

.labGrowBoxMain {
    display: flex;
    margin-inline: 90px;
}

.labGrowBox1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e1e1;
}

.labGrowBox1Title {
    font-size: 25px;
    color: #7d7f85;
    text-align: center;
    font-family: 'FreightDispProMedium-Regular,Times New Roman,serif';
}

.labGrowBox1Desc {
    width: 60%;
    text-align: center;
    color: #7d7f85;
}

.labGrowBox2 {
    width: 50%;
    height: 550px;
}

.labDrowTableTitle {
    text-align: center;
    font-size: 25px;
    color: #7d7f85;
    font-family: 'FreightDispProMedium-Regular,Times New Roman,serif';

}

.labGrowTableMain {
    border: 1px solid #e1e1e1;
    width: 100px; 
    white-space: nowrap;
    overflow-x: scroll;
}

.labGrowTableDa {
    font-weight: 400;
    color: #7d7f85;
    padding: 15px;
}

.labGrowTableTH {
    width: 290px;
    padding: 15px;
    background-color: #f8f8f8;
    color: #7d7f85;
    border-left: 1px solid #e1e1e1;
}

.labGroTableBottomDesc {
    font-size: 13px;
    color: #7d7f85;
    width: 880px;
    padding-block: 60px;
}

.labGroProcessImg {
    height: 550px;
}

.labGrowMoreBtn {
    height: 50px;
    background-color: #7d7f85;
    color: white;
    border: none;
    font-size: 13px;
    padding: 15px;
    transition: all 0.5s ease;
    font-weight: 500;
    padding-inline: 30px;
}

.labGrowMoreBtn:hover {
    background-color: white;
    color: #7d7f85;
    border: 1px solid #7d7f85;
}

.labGrowZenithImg {
    width: 70%;
}

.labGrowBoxImages {
    height: 100%;
    width: 100%;
    margin-top: 5px;
}

/* 1px solid #e1e1e1 */

@media screen and (max-width: 1300px) {

    .labGrowBoxMain {
        display: flex;
        margin-inline: 10px;
    }

}


@media screen and (max-width: 1010px) {
    .labGrowMain {}

    .labGrowBoxMain {
        display: flex;
        flex-direction: column;
    }

    .labGrowBox1 {
        order: 2;
        width: 100%;
    }

    .labGrowBox2 {
        order: 1;
        width: 100%;
        object-fit: cover;
    }

    .labGrowBoxImages {
        object-fit: cover;
    }
    .labGrowBox1Desc{
        width: 97%;
    }

    .labGroTableBottomDesc{
        margin-inline: 20px;
    }
}

@media screen and (max-width: 500px) {
    .labGrowBox2 {
        height: auto;
    }
    .labGroProcessImg{
        height: 250px;
    }
   
}

@media screen and (max-width: 350px) {
    .labGrowTableMain {
        margin-left: 250px!important;
    }
}
@media screen and (max-width: 450px) {
    .labGrowTableMain {
        margin-left: 200px;
    }
}
@media screen and (max-width: 600px) {
    .labGrowTableMain {
        margin-left: 150px;
    }
}




@media screen and (max-width: 400px) {
  
    .labGrowMoreBtn{
        height: 70px;
    }
}


