.daimondEvery {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 30%;
}

.daimondEveryTitle {
  font-size: 25px;
  color: #7d7f85;
  font-family: 'FreightDispProMedium-Regular,Times New Roman,serif';
  padding: 45px;
  margin-top: 40px;
}


.daimondLearnMore {
  position: relative;
  display: inline-block;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.daimondLearnMore::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #333;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}

.daimondLearnMore:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

@media screen and (max-width: 1024px) {

  .daimondEveryTitle {
    padding: 0px;
    text-align: center;
    font-size: 22px !important;
  }

  .daimondEvery {
    margin-inline: 5%;
  }

  .daimondEveryTitleDesc {
    display: none;
  }

  .daimondLearnMore {
    display: none;
  }
}