body{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.mainbanner_tnc{
    object-fit: cover;
    min-height: 750px;
    max-height: 750px;
    width: 100%;
}
.centerall_tnc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tnctitle{
    height: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(75 71 71);
    margin-top: 15px;
    margin-bottom: 15px;
}
.tnctitle_2{
    height: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
    padding-left: 3.5rem;
}
.tnc_content{
    margin: 0 auto;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 18px;
    line-height: 20px;
    color: #424141;
    word-wrap: break-word;
}
.contentboxtnc{
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.tncbannerpart{
    position: relative;
}
.tnclogo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 300px;
    max-height: 300px;
}