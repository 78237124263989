.company-banner {
  color: rgba(29, 50, 88, 0.8) !important;
  text-align: center;
  font-size: 22px;
  background: rgb(242,242,242);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 19px 38px, rgba(0, 0, 0, 0.01) 0px 15px 12px;
}

.banner-stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.stat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 30px;
}

.rightBorder{
  border-right: 1px solid #ddd;
}

.firstspantag{
  font-size: 20px;
  font-weight: bold;
}

.firstSpanData{
font-size: 30px;
font-weight: bold;
}

.firstptage{
  font-size: 20px;
  margin-top: 5px;
}
.ptag{
  font-size: 30px;
  margin-top: 30px;
}

@media (min-width: 446px) and (max-width: 768px) {
  .stat {
    padding: 33px;
    padding-bottom: 0px;
  }
  .company-banner {
    font-size: 12px;
  }
  .firstSpanData{
    font-size: 25px;
    }
    
    .firstptage{
      font-size: 14px;
      margin-top: 5px;
    }
    .ptag{
      font-size: 14px;
      margin-top: 10px;
    }
}

@media (max-width: 445px) {
  .stat {
    padding: 33px;
    padding-bottom: 0px;
  }
  .company-banner {
    font-size: 12px;
  }
  .firstSpanData{
    font-size: 25px;
    }
    
    .firstptage{
      font-size: 14px;
      margin-top: 5px;
    }
    .ptag{
      font-size: 14px;
      margin-top: 10px;
    }
}
