.gorFQAMain {
    margin-inline: 15%;
    margin-top: 8%;
}

.gorTopSearchBox {
    width: 100%;
    height: 35px;
}

.gorFAQBox {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin-top: -30px;
}

.gorFAQBoxMain {
    width: 50%;
}

.gorBoxfaqTitle {
    font-family: 'Freight Big Pro,serif';
    font-weight: 300;
    font-size: 30px;
}

.gorBoxfaqDesc {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
}


@media screen and (max-width:1440px) {
    .gorFQAMain {
        margin-inline: 2%;
        margin-top: 15%;
    }
}




@media screen and (max-width: 800px) {

    .gorFQAMain {
        margin-inline: 2%;
        margin-top: 25%;
    }

    .gorFAQBox {
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-top: -30px;
    }

    .gorFAQBoxMain {
        width: 100%;
    }
}

@media screen and (max-width: 390px) {
    .gorFQAMain {
        margin-top: 30%;
    }
}