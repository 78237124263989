

.smilingCelebMain{
    width: 100%;
}

.smilingCelebMainSub{
    width: 100%;
}

.smilingCeleMainTopImage{
    width: 100%;
    padding-inline: 6%;

}
.smilingCelebBoxMain{
    margin-inline: 6%;
}
@media screen and (max-width: 1440px) {

    .smilingCeleMainTopImage{
        padding-inline: 2%;
    
    }
    .smilingCelebBoxMain{
        margin-inline: 2%;
    }
}