.main-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-outer-container{
    height:auto;
    width:1555.83px;
    margin:20px;
    border:1px solid #d3d1d0;
    border-radius:4px;
    display: flex;
}

.have-an-account{
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:30px
}

.have-an-account-text{
    font-size: 16px;
    color:#333333;
    font-weight:500;
}

.have-an-account-button{
    border: 1px solid #26a5d4;
    background: #fff;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
    color:'#333333';
    border-radius: 4px;
    cursor: pointer;
}

.create-an-account-container{
    width:50%;
    background:#f2f2f2;
    display: flex;
    justify-content: center;
    height: auto;
}

.caa-inner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:616.91px;
    height: auto;
    gap:30px;
    margin-bottom: 30px;
    margin-top: 25px;
    padding: 15px;
}

.input-outer-container{
    display:flex;
    flex-direction:column;
    width:100%;
    gap:10px;
}

.input-inner-container{
    display: flex;
    flex-direction: column;
    gap:8px;
    width:100%;
}

label{
    font-size: 16px;
}

.inpfield{
 height: 40px;
 transition: 0.3s all;
 border: 1px solid #45d4d5;
 outline: none;
 border-radius: 2px;
 font-size: 15px;
 padding-left: 10px;
}

.inpfield:focus{
    box-shadow: 0 0 0 0.2rem rgba(38,165,212,.25);
    background-color: #fffaec;
}

.pho{
    border:1px solid #d3d1d0
}

.err{
    border:1px solid #eb5757
}

.err:focus{
    box-shadow: 0 0 0 0.2rem rgba(235,87,87,.25);
}

.suc{
    border: 1px solid #27ae60;
}

.suc:focus{
    box-shadow: 0 0 0 0.2rem rgba(39,174,96,.25);
}


.reg-btn{
    background-color: #45d4d5;
    outline: none;
    border:none;
    border-radius: 4px;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 30px;
    font-weight: lighter;
    color:#333333;
    cursor: pointer;
    margin-top: -20px;
}

.googleset-btn{
    background-color: #DD4B39;
    border:none;
    display: flex;
    gap:5px;
    padding:8px 90px;
    border-radius: 4px;
    cursor: pointer;
}

.error-box{
    color: #7a2d2d;
    background-color: #fbdddd;
    border-color: #f9d0d0;
    width:100%;
    border-radius: 4px;
}

@media screen and (max-width:726px) {
    .main-outer-container{
        flex-wrap: wrap;
        width:100%;
    }

    .have-an-account{
        width:100%;
        order: 1;
        margin: 30px 0px;
    }

    .create-an-account-container{
        width:100%;
        order:2;
    }
}

