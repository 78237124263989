.shopifyMain {
    display: flex;
    margin-inline: 90px;
    margin-block: 60px;

}

.shopifyDesc {
    border: 0.5px solid #e1e1e1;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shopifyMainImage {
    /* border: 1px solid; */
    width: 50%;
}

.shopifyTitle {
    color: #7d7f85;
    font-size: 30px;
    text-align: center;
    font-family: 'FreightDispProMedium-Regular,Times New Roman,serif';
}
.shopifyDescription{
    width: 70%;
    font-size: 13px;
    text-align: center;
}



@media screen and (max-width: 1000px) {
    .shopifyMain {
        display: flex;
        flex-direction: column;    
        margin-inline: 10px;
        margin-block: 60px;
    
    }

    .shopifyDesc {
        width: 100%;
        order: 2;
    }
    
    .shopifyMainImage {
        width: 100%;
        order: 1;
    }

    .shopifyDescription{
        color: #7d7f85!important;
        width: 95%!important;
    }

    .shopifyTitle{
        font-size: 22px!important;
    }
}