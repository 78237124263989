.smilingBlogMain{
    background-color: white;
    width: 100%;
    margin-inline: 6%;
}

.srblog-head{
    width:600px;
    font-size:14px;
    color:#7f7d85;
    font-weight:500;
}

@media screen and (max-width: 1000px) {
    .smilingBlogMainSub {
        width: 100%!important;
    }
  
}

@media screen and (max-width: 660px) {
    .srblog-head{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        font-size:14px;
        color:#7f7d85;
        font-weight:500;
    }
  
}