#outlined-confirm-password-input::-ms-reveal {
    display: none !important;
}


.submitBtnForgot {
    height: 40px;
    width: 430px;
    border: 1px solid black;
    margin-top: 10px;
    font-size: 14px;
    color: #424242;
    font-weight: 600 !important;
    letter-spacing: 1px;
    transition: all 0.5s ease;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer;
    background-color: white;
}

.submitBtnForgot:hover {
    background-color: rgba(66, 66, 66, 0.05);
}

@media screen and (max-width: 700px) {

    .SmilingLoginCodeBtn {
        width: 50%;
        height: 50px!important;

    }
   
}

@media screen and (max-width: 500px) {

    .SmilingLoginCodeBtn {
        width: 70%;
        height: 40px!important;
        font-size: 12px!important;

    }
}