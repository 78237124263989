.simlimgCofirmationMain {
    margin-inline: 6%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SmilingthanksImg {
    height: 250px;
    width: 250px
}
.confritmSubmain{
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contiShopiBtn{
    height: 40px;
    width: 200px;
    outline: none;
    background-color: white;
    border: 1px solid lightgray;
    font-weight: 500;
    border-radius: 5px;
}
