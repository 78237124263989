.searchLine{
    width: 90%;
    margin-inline: 20px;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    height: 40px;
}

.serachName{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.popularSearchName{
    height: 35px;
    padding: 10px;
    text-align: center;
    align-items: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-inline: 10px;
    color: rgb(136, 136, 136);
    background-color: rgb(239, 239, 239);
}

.imagesBorder{
    height: 150px;
    width: 150px;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popularImage{

    height: 120px;
    width: 120px;
}