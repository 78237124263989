.persnolForm {
    display: flex;
    flex-direction: row;
}

.shippingAddress {
    width: 940px;
    /* border: 1px solid black; */
    margin: 70px;
    display: flex;
    flex-direction: column;
    order: 1;
}

.orderReview {
    width: 750px;
    background-color: #fcfcfc;
    border: 1px solid #cecece;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    order: 2;
}

.stateSelect {
    width: 450px;
    height: 40px;
    border: 1px solid #ced4da;

}

.CountryLabel {
    width: 100%;
    background-color: rgb(233, 236, 239);
    border: 0;
    height: 40px;
}

.PromoTitle {
    margin-top: 30px;
}

.promoCode {
    height: 40px;
    width: 100%;
    border: 1px solid #26a5d4;
}

.formMainInput {
    display: flex;
    flex-direction: row;
    height: 100px;
}

input.form-Control:invalid {
    border: 1px solid #45d4d5;
}

.formMainFont {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    margin: 0;
    height: 30px;
}

.form-Control {
    display: block;
    width: 450px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.Error {
    color: red;
    font-size: 13px;
    margin: 0;
}


.alert-danger {
    color: #7a2d2d;
    background-color: #fbdddd;
    border-color: #f9d0d0;
}

.alert-link {
    font-weight: 700;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

button,
select {
    text-transform: none;
}

.useBtn {
    position: absolute;
    right: 90px;
    height: 40px;
    width: 150px;
    background-color: white;
    cursor: pointer;
    border: 1px solid #26a5d4;
    border-radius: 5px;
    color: #26a5d4;
    font-size: 17px
}

.textMain {
    height: 100px;
}


@media screen and (min-width: 1441px) {
    .mobileShippingAddress {
        display: none;
    }
}

@media screen and (max-width: 320px) {

    .persnolForm {
        display: flex;
        flex-direction: column;
    }

    .orderReview {
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #cecece;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .orderTitle {
        font-size: 20px !important;
        font-weight: 600 !important;
        text-decoration: underline;
    }

    .orderOne {
        font-size: 15px !important;

    }

    .orderOnePrice {
        font-size: 15px !important;

    }

    .oneOrder {
        margin-top: 30px !important;
    }



    .shippingAddress {
        display: none;
    }

    .mobileShippingAddress {
        /* width: 940px; */
        /* border: 1px solid black; */
        margin: 20px !important;
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .useBtn {
        right: 10px!important;
        width: 120px!important;
    }

    .formMainFontMobile {
        font-family: Lato, sans-serif;
        font-size: 1rem !important;
        font-weight: 600 !important;
        /* line-height: 2.5!important; */
        color: #333;
        margin: 0;
        /* height: 60px!important; */
    }

    .formMainInput {
        display: flex;
        flex-direction: column !important;
        /* height: 300px!important; */
    }

    .form-Control-mobile {
        display: block;
        width: 450px;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        /* line-height: 2.5!important; */
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .textMain {
        /* height: 150px!important; */
    }
    .bottomFont{
        display: none;
    }



}

@media screen and (max-width: 375px) {

    .persnolForm {
        display: flex;
        flex-direction: column;
    }

    .orderReview {
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #cecece;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .orderTitle {
        font-size: 20px !important;
        font-weight: 600 !important;
        text-decoration: underline;
    }

    .orderOne {
        font-size: 15px !important;

    }

    .orderOnePrice {
        font-size: 15px !important;

    }

    .oneOrder {
        margin-top: 30px !important;
    }



    .shippingAddress {
        display: none;
    }

    .mobileShippingAddress {
        /* width: 940px; */
        /* border: 1px solid black; */
        margin: 20px !important;
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .useBtn {
        right: 10px!important;
        width: 120px!important;
    }

    .formMainFontMobile {
        font-family: Lato, sans-serif;
        font-size: 1rem !important;
        font-weight: 600 !important;
        /* line-height: 2.5!important; */
        color: #333;
        margin: 0;
        /* height: 60px!important; */
    }

    .formMainInput {
        display: flex;
        flex-direction: column !important;
        /* height: 300px!important; */
    }

    .form-Control-mobile {
        display: block;
        width: 450px;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        /* line-height: 2.5!important; */
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .textMain {
        /* height: 150px!important; */
    }
    .bottomFont{
        display: none;
    }



}

@media screen and (max-width: 425px) {

    .persnolForm {
        display: flex;
        flex-direction: column;
    }

    .orderReview {
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #cecece;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .orderTitle {
        font-size: 20px !important;
        font-weight: 600 !important;
        text-decoration: underline;
    }

    .orderOne {
        font-size: 15px !important;

    }

    .orderOnePrice {
        font-size: 15px !important;

    }

    .oneOrder {
        margin-top: 30px !important;
    }



    .shippingAddress {
        display: none;
    }

    .mobileShippingAddress {
        /* width: 940px; */
        /* border: 1px solid black; */
        margin: 20px !important;
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .useBtn {
        right: 10px!important;
        width: 120px!important;
    }

    .formMainFontMobile {
        font-family: Lato, sans-serif;
        font-size: 1rem !important;
        font-weight: 600 !important;
        /* line-height: 2.5!important; */
        color: #333;
        margin: 0;
        /* height: 60px!important; */
    }

    .formMainInput {
        display: flex;
        flex-direction: column !important;
        /* height: 300px!important; */
    }

    .form-Control-mobile {
        display: block;
        width: 450px;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        /* line-height: 2.5!important; */
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .textMain {
        /* height: 150px!important; */
    }
    .bottomFont{
        display: none;
    }



}

@media screen and (max-width: 770px) {

    .persnolForm {
        display: flex;
        flex-direction: column;
    }

    .orderReview {
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #cecece;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .orderTitle {
        font-size: 20px !important;
        font-weight: 600 !important;
        text-decoration: underline;
    }

    .orderOne {
        font-size: 15px !important;

    }

    .orderOnePrice {
        font-size: 15px !important;

    }

    .oneOrder {
        margin-top: 30px !important;
    }



    .shippingAddress {
        display: none;
    }

    .mobileShippingAddress {
        /* width: 940px; */
        /* border: 1px solid black; */
        margin: 20px !important;
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .useBtn {
        right: 10px!important;
        width: 120px!important;
    }

    .formMainFontMobile {
        font-family: Lato, sans-serif;
        font-size: 1rem !important;
        font-weight: 600 !important;
        /* line-height: 2.5!important; */
        color: #333;
        margin: 0;
        /* height: 60px!important; */
    }

    .formMainInput {
        display: flex;
        flex-direction: column !important;
        /* height: 300px!important; */
    }

    .form-Control-mobile {
        display: block;
        width: 450px;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        /* line-height: 2.5!important; */
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .textMain {
        /* height: 150px!important; */
    }
    .bottomFont{
        display: none;
    }



}

@media screen and (max-width: 1024px) {

    .persnolForm {
        display: flex;
        flex-direction: column;
    }

    .orderReview {
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #cecece;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .orderTitle {
        font-size: 20px !important;
        font-weight: 600 !important;
        text-decoration: underline;
    }

    .orderOne {
        font-size: 15px !important;

    }

    .orderOnePrice {
        font-size: 15px !important;

    }

    .oneOrder {
        margin-top: 30px !important;
    }



    .shippingAddress {
        display: none;
    }

    .mobileShippingAddress {
        /* width: 940px; */
        /* border: 1px solid black; */
        margin: 20px !important;
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .useBtn {
        right: 10px!important;
        width: 120px!important;
    }

    .formMainFontMobile {
        font-family: Lato, sans-serif;
        font-size: 1rem !important;
        font-weight: 600 !important;
        /* line-height: 2.5!important; */
        color: #333;
        margin: 0;
        /* height: 60px!important; */
    }

    .formMainInput {
        display: flex;
        flex-direction: column !important;
        /* height: 300px!important; */
    }

    .form-Control-mobile {
        display: block;
        width: 450px;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        /* line-height: 2.5!important; */
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .textMain {
        /* height: 150px!important; */
    }
    .bottomFont{
        display: none;
    }



}

@media screen and (max-width: 1440px) {

    .persnolForm {
        display: flex;
        flex-direction: column;
    }

    .orderReview {
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #cecece;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        order: 1;
    }

    .orderTitle {
        font-size: 20px !important;
        font-weight: 600 !important;
        text-decoration: underline;
    }

    .orderOne {
        font-size: 15px !important;

    }

    .orderOnePrice {
        font-size: 15px !important;

    }

    .oneOrder {
        margin-top: 30px !important;
    }



    .shippingAddress {
        display: none;
    }

    .mobileShippingAddress {
        /* width: 940px; */
        /* border: 1px solid black; */
        margin: 20px !important;
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .useBtn {
        right: 10px!important;
        width: 120px!important;
    }

    .formMainFontMobile {
        font-family: Lato, sans-serif;
        font-size: 1rem !important;
        font-weight: 600 !important;
        /* line-height: 2.5!important; */
        color: #333;
        margin: 0;
        /* height: 60px!important; */
    }

    .formMainInput {
        display: flex;
        flex-direction: column !important;
        /* height: 300px!important; */
    }

    .form-Control-mobile {
        display: block;
        width: 450px;
        height: 50px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        /* line-height: 2.5!important; */
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .textMain {
        /* height: 150px!important; */
    }
    .bottomFont{
        display: none;
    }



}