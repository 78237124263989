

.techMain{
    width: 1200px;
    display: flex;
    padding-bottom: 20px;
}
.techConsultation{
    background-color: #e4e4e4;
    border: 1px solid rgba(0,0,0,.125);
    margin-inline: 20px;
    height: fit-content;
    padding: 10px;
    width: 55%;
}

.techCertfication{
    background-color: #e4e4e4;
    border: 1px solid rgba(0,0,0,.125);
    padding: 10px;
    width: 45%;
}