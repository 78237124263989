.smilingBridesMain {
    display: flex;
    margin-inline: 90px;
}

.enagementBtn {
    height: 65px;
    color: white;
    border: none;
    margin-inline: 30px;
    background-color: #7d7f85;
}

.enagementBtn:hover {
    background-color: white;
    border: 1px solid #7d7f85;
    color: #7d7f85;
    transition: all 0.3s ease;
}


.smilingBrides {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0.5px solid #e1e1e1;
}

.smlingBridesImages {
    width: 75%;
}

.smilingMainImages {
    height: 650px;
    width: 100%;
    margin-top: 5px;
    object-fit: cover;
}

@media screen and (max-width: 1024px) {
    .smilingBridesMain {
        margin-inline: 10px;
    }
}


@media screen and (max-width: 900px) {
    .smilingBridesMain {
        display: flex;
        flex-direction: column;
    }

    .smlingBridesImages {
        width: 100%;
    }

    .smilingBrides {
        width: 100%;
        order: 2;
    }

    .enagementBtn {
        height: 65px;
        color: white;
        border: none;
        margin-inline: 40px;
        background-color: #7d7f85;
    }

    .linkingTitle{
        margin-top: 25px!important;
        font-size: 22px!important;
    }

    .linkingShopCol{
        font-weight: 700!important;
    }

    .ring1Desc{
        font-size: 12px!important;
        letter-spacing: 1px!important;
        font-weight: 500!important;
        color: #7d7f85!important;
    }
}

@media screen and (max-width: 500px) {

    .smilingBridesMain {
        margin-top: 40px !important;
        border: none !important;
    }
    .smilingBrides{
        border: none !important;
    }

    .smilingMainImages {
        height: 350px;
        width: 100%;
        margin-top: 5px;
    }

    .smilingBridesMainTitle {
        font-size: 22px !important;
        margin-top: 20px;
    }


    .enagementBtn {
        width: 100% !important;
        margin-inline: 2px;
        height: 45px !important;
        font-weight: 500;
        font-size: 13px;
    }

}