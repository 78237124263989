.ElveFooterMain {
    color: rgba(29, 50, 88, 0.8) !important;
    display: flex;
    border-top: 1px solid black;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-inline: 10px;
}

.ElveFooter1 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.elveBox1Title {
    font-size: 19px;
    font-weight: bold;
    /* margin: 0px; */
    margin-left: 15%;
}

.eleBox1InputBox {
    height: 40px;
    width: 40%;
    border: none;
    background-color: transparent;
    border: 1px solid #00000078;
    outline: none;
}

.elevBox1Btn {
    height: 40px;
    width: 160px;
    border: 1px solid #00000078;
    background-color: #ebdcc3;
    font-weight: 500;
    font-size: 18px;

}

.ElveFooter2 {
    width: 20%;
}

.ElveFooter3 {
    width: 20%;
}

.ElveFooter4 {
    width: 20%;
}

.ElevFooterBoxTitle {
    font-size: 20px;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
}

.ElveFooterDesc {
    font-size: 15px;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer;
    margin-bottom: 9px;
    width: fit-content;
}

.ElevBox4Title {
    font-size: 14px;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer;
}

.footerIconMain {
    display: flex;
    margin-top: 30px;
}

.ElevFooterDesc {
    opacity: 0;
    /* Initially hide the content */
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    /* Add transition for max-height and opacity */
}

.ElevFooterDesc .show {
    opacity: 1;
    /* Show the content */
    max-height: 100px;
    /* Adjust height as needed */
}

.footerSocialIcon {
    height: 45px;
    width: 45px;
    border: 1px solid #e1e1e1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-inline: 5px;
}

.footerSocialIcon:hover {
    border-color: #7d7f85;
}

.footerOfficeDesc {
    font-size: 15px;
}

.eleBox1InputBox::placeholder {
    padding-left: 10px;
}

.ElveFooterDesc:hover {
    text-decoration: underline;
}

@media screen and (max-width:1500px) {

    .ElevFooterBoxTitle {
        font-size: 20px;
    }

    .ElveFooterDesc {
        font-size: 13px;
    }

    .elveBox1Title {
        font-size: 20px;
    }

    .footerOfficeDesc {
        font-size: 13px;
        height: auto;
    }

    .eleBox1InputBox {
        width: 60%;
    }
}

@media screen and (max-width: 1400px) {

    .elevBox1Btn {
        width: 100px;
        color: black;
        font-size: 14px;
    }

    .eleBox1InputBox{
        border-radius: 0px!important;
    }
}

@media screen and (max-width: 960px) {

    .ElveFooterMain {
        display: flex;
        flex-direction: column;
    }

    .ElveFooter1Input {
        display: flex;
        justify-content: center;
    }

    .ElveFooter1 {
        padding: 0px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .ElveFooter2 {
        width: 100%;
        margin-top: 10px;
        padding-inline: 15px;
    }

    .elveBox1Title {
        margin-left: 0px !important;
    }


    .ElveFooter3 {
        width: 100%;
        margin-top: 10px;
        padding-inline: 15px;
    }


    .ElveFooter4 {
        width: 100%;
        margin-top: 10px;
        padding-inline: 15px;
    }

}

@media screen and (max-width: 600px) {

    .ElveFooter1Input {
        width: 100%;
    }

    .eleBox1InputBox {
        width: 60% !important;
    }

    .elevBox1Btn {
        width: 30%;
        font-size: 15px;
    }
}