
.gorjanaTrade1Web{
    cursor: pointer;
    position: relative;
}

.gorjanaTrideBox {
    position: absolute;
    right: 20%;
    margin-top: -20%;
}



@media screen and (min-width:751px) {
    .gorjanaTrade1Mobile {
        display: none;
    }

 
}

@media screen and (max-width:750px) {

    .gorjanaTrade1Web{
        display: none;
    }

    .gorjanaTrade1Mobile{
        cursor: pointer;
        position: relative;
    }

    .gorjanaTrideBoxMobile{
        position: absolute;
        bottom: 20%;
        text-align: center;
        width: 100%;
    }
}