.gorCarresMain{
    margin-top: 130px;
    margin-inline: 25%;
}


.gorAssRetailITitle{
    color: #C7B090;
    font-size: 17px;
    font-weight: 600;
    margin: 0px;
    cursor: pointer;
}

.gorAssRetailITitle:hover{
    text-decoration: underline;
}

@media screen and (max-width: 1100px) {
    .gorCarresMain{
        margin-inline: 10%;
    }

}

@media screen and (max-width: 500px) {
    .gorCarresMain{
        margin-inline: 10%;
    }

    .gorCarreBox{
        display: flex;
        flex-direction: column;
    }

    .gorCarreBoxOffi{
        margin-top: 20px!important;
        margin-left: 0px!important;
    }
    .gorCarreBoxOffSelect{
        margin-left: 0px!important;

    }

}