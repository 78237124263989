.exploreMain {
    display: flex;
    gap: 40px;
    margin-top: 30px;
}

@media screen and (max-width:1100px) {
    .exploreMain {
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 800px) {
    .smilingExpolerImgs{
        width: 100%!important;
        padding: 20px;
        object-fit: contain!important;
        height: fit-content!important;
    }

    .smilingExpolerBoxDesc{
        
    }

}

@media screen and (max-width: 500px) {

    .smilingExpolerBoxDesc{
        width: 200px!important;
    }

}

