@media screen and (min-width:1025px) {
    .sliderCardMobile {
        display: none;
    }
}

@media screen and (max-width:1024px) {
    .sliderCardWeb {
        display: none;
    }

    .scrollable-container {
        padding: 20px;
        overflow-x: auto;
    }

    .image-scroll-content {
        display: flex;
        gap: 10px;
        width: 270%;
    }

    .image-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .image-wrapper img {
        display: flex;
        max-width: 100%;
        max-height: 100%;
    }

    ::-webkit-scrollbar {
        width: 0;
    }

    .image-scroll-content img {
        max-height: 10%;
        width: auto;
    }
}