/* .header + .sallercard {
    position: relative;
}

.index-container{
    width:375px;
}


@media screen and (max-width:375px) {
    .index-container{
        width:375px;
    }
} */


@media screen and (min-width:769px) {
    .JewelleryGiftsBannerPage{
        display: none!important;
    }
}