

.shopbycategoryTitle{
    font-family: 'FreightDispProMedium-Regular,Times New Roman,serif';
    font-size: 40px;
    text-align: center;
    color: rgb(125, 127, 133);
}

.shopbycategoryDesc{
    display: flex;
    justify-content: center;
}

.shopByCategoryBox{
    margin: 30px;  
}
.shopByCategoryBoxImg{
    height: 200px;
    width: 200px;
}

.shopByCategoryBox1Main{
    display: flex;
}

.shopByCategoryBox2Main{
    display: flex;
}

@media screen and (max-width: 550px) {

    .shopbycategoryTitle{
        margin-top: 50px!important;
    }

    .shopByCategoryBox{
        width: 50%!important;
        margin: 5px!important;
    }

    .shopByCategoryBoxImg{
        height: 80%!important;
        width: 100%!important;
    }

    .shopbycategoryTitle{
        font-size: 40px!important;
    }

    .shopByCategoryBox{
        padding: 20px!important;
    }
}