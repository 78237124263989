.gorWhoTopImg{
    width: 10%;
    margin: 20px;
}

.gorWhoMain{
    margin-inline: 22%;
    margin-top: 12%;
    display: flex;
}

.gorWhoBox1{
    width: 70%;

    display: flex;
    flex-direction: column;
}
.gorWhoBox2{
    width: 50%;
}
.gorWhoEmail{
    border: 0px;
    border-bottom: 1px solid;
    height: 40px;
    width: 70%;


}
.gorWhoPass{
    border: 0px;
    border-bottom: 1px solid;
    margin-top: 15px;
    height: 40px;
    width: 70%;

}

.gorWhoSignBtn{
    height: 45px;
    color: white;
    border: 0;
    background-color: #283045;
    width: 70%;
    font-weight: 500;
}
.gorWhoSignBtn:hover{
    background-color: white;
    color: #283045;
    border: 1px solid #283045;
}

.gorWhoApplyBtn{
    height: 45px;
    color: white;
    border: 0;
    background-color: #283045;
    width: 100%;
    font-weight: 500;
}
.gorWhoApplyBtn:hover{
    background-color: white;
    color: #283045;
    border: 1px solid #283045;
}

@media screen and (max-width: 1441px) {

    .gorWhoTopImg{
        width: 30%;
        margin: 20px;
    }

    .gorWhoMain{
        margin-inline: 8%;
        margin-top: 12%;
    }
}
@media screen and (max-width: 800px) {

    .gorWhoTopImg{
        width: 40%;
        margin: 20px;
    }

    .gorWhoMain{
        margin-inline: 2%;
        display: flex;
        flex-direction: column;

    }

    .gorWhoBox1{
        width: 100%;
    }

    .gorWhoEmail{
       width: 100%;
    }
    .gorWhoPass{
        width: 100%;
    }
    .gorWhoSignBtn{
        width: 100%;
    }
    .gorWhoBox2{
        width: 100%;
        margin-top: 30px;
    }
    .gorWhoForgot{
        width: 100%!important;
    }
}