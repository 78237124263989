.GorMansoryMain {
    display: flex;
    margin-inline: 15%;
}

.gorMasonryMainTitle {
    margin-top: 90px;
    font-size: 35px;
    font-family: 'Freight Big Pro,serif';
    margin-inline: 15%;
    margin-bottom: 0px;
}

.GorMansoryBox1 {
    width: 50%;
}

.GorMansoryBox1Img {
    width: 90%;
}

.GorMansoryBox2 {
    width: 50%;
    display: flex;
    align-items: center;

}

.GorMansoryBox2Img {
    width: 90%;
}

.GorMansoryBox1ImageMain {
    position: relative;
}

.GorMansoryBox2ImageMain {
    position: relative;

}

.GorMansoryBtn {
    height: 50px;
    width: 200px;
    background-color: #283045;
    color: white;
    font-size: 13px;
    font-weight: 500;
}

.gorShopLookBtn {
    border: none;
    padding: 7px;
}

.gorShopLookBtnMain {
    position: absolute;
    background-color: #f0f0f0;
    bottom: 20px;
    right: 80px;
}


@media screen and (max-width: 1441px) {
    .GorMansoryMain {
        margin-inline: 8%;
    }
}

@media screen and (max-width: 1000px) {
    .GorMansoryMain {
        margin-inline: 4%;
    }
}

@media screen and (max-width: 750px) {
    .GorMansoryMain {
        margin-inline: 4%;
        display: flex;
        flex-direction: column;
    }

    .gorMasonryMainTitle {
        margin-inline: 0%;
        text-align: center;
        margin-top: 50px;
    }

    .GorMansoryBox1 {
        width: 100%;
    }

    .GorMansoryBox1Img {
        width: 100%;
    }

    .GorMansoryBox2 {
        width: 100%;
        margin-top: 50px;
    }
    .GorMansoryBox2Img{
        width: 100%;
    }

    .MasonryBtnMain{
        margin-block: 30px!important;
    }
}