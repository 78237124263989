.css-1wc848c-MuiFormHelperText-root{
    margin-right: 0px!important;
    margin-left: 0px!important;
}



.labgrowRegister {
    width: 420px;
    margin: 10px;
}


.SmilingSignInHere{
    color: #7d7f85;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 500;

    position: relative;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
}
.SmilingSignInHere::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.3s ease;
}
.SmilingSignInHere:hover::before {
    width: 100%; 
}


@media screen and (max-width: 700px) {
    .labgrowRegister {
        width: 90%;
        margin: 10px;
    }
}