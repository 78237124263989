.muiSmilingRocksBtnManage{
    /* border: 1px solid #7d7f85 !important; */
    font-family: "PT Sans", sans-serif !important;
    font-size: 13.5px !important;
    font-weight: 600 !important;
    opacity: .8 !important;
    margin-left: auto !important;
    display: block !important;
    background: #f6efe6 !important;
    color: rgba(31, 25, 25, 0.7) !important;
}

.manageAddressBlock{
    border: 1px solid #e9e9ed;
    padding: 15px 15px 65px 15px;
    position: relative;
    height: 100%;
}

.muiSmilingRocksBtnManageEdit{
    /* border: 1px solid #7d7f85 !important; */
    font-family: "PT Sans", sans-serif !important;
    font-size: 13.5px !important;
    font-weight: 700 !important;
    opacity: .8 !important;
    padding: 7px 20px 7px 20px !important;
    background: rgb(246,239,230) !important;
    color: rgba(31, 25, 25, 0.7) !important;
}
.muiSmilingRocksBtnManage:hover, .muiSmilingRocksBtnManageEdit:hover{
    background: #fff !important;
    color: #1f1919;
    opacity: .8;
}

.addressMainSec{
    gap: 2%;
}

.AddressSec{
    width: 32%;
    min-width: 32%;
    margin-bottom: 2%;
}

.manageAddressInputRadio{
    accent-color: #7d7f85;
    margin-right: 10px;
    margin-left: 1px;
}

.loadingBarManage{
    color: #7d7f85 !important;
}

.manageAddressDefault{
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* border: 1px solid rgba(0, 0, 0, 0.35); */

}

@media(max-width: 1024px){
    .AddressSec{
        width: 49%;
        min-width: 49%;
        /* margin-bottom: 1%; */
    }

}

@media(max-width: 768px){
    .manageAddressSec > div{    
        padding: 0 !important;
    }

    .savedAddress{
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .savedAddressManageBtn{
        padding: 3px 10px !important;
    }
}

@media(max-width: 576px){
    .AddressSec{
        width: 100%;
        min-width: 100%;
    }

    .addressMainSec{
        gap: 0;
    }
}