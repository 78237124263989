.css-1wc848c-MuiFormHelperText-root {
    margin-right: 0px !important;
    margin-left: 0px !important;
}


.smling-register-main {
    margin-inline: 6%;
    background-color: white;
    width: 60%;
}

.labgrowRegister {
    width: 420px;
    margin: 10px;
}

.createBtnRegister {
    height: 40px;
    width: 95%;
    margin-inline: 15px;
    border: 1px solid black;
    margin-top: 10px;
    font-size: 14.5px;
    color: #1f1919;
    opacity: .8;
    font-weight: 600 !important;
    letter-spacing: 1px;
    transition: all 0.5s ease;
    font-family: "PT Sans", sans-serif;
    cursor: pointer;
    background-color: white;
}

.createBtnRegister:hover {
    background-color: rgba(66, 66, 66, 0.05);
}

.SmilingSignInHere {
    color: #7d7f85;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 500;

    position: relative;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
}

.SmilingSignInHere::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.3s ease;
}

.SmilingSignInHere:hover::before {
    width: 100%;
}

#outlined-password-input::-ms-reveal {
    display: none !important;
}

#outlined-confirm-password-input::-ms-reveal {
    display: none !important;
}

@media screen and (max-width: 700px) {

    
    .smling-register-main {
        width: 90%;
    }
    
    .AuthScreenRegisterMainTitle {
        padding-block: 10px !important;
        padding-bottom: 0px !important;
        font-size: 25px !important;

    }

    .labgrowRegister {
        width: 80%;
        margin: 10px;
    }

    .createBtnRegister {
        width: 90%;
    }

    .smling-register-main {
        margin-inline: 2% !important;

    }

}