.finejwelery {
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    list-style: none;
    color: #1f1919 !important;
    opacity: 0.5;
    font-size: 14.5px;
    font-weight: bold;
}

.finejwelery:hover {
    opacity: 1;
}

.smilingProductMain {
    width: 100%;
    background-color: white;
}

.finejli {
    list-style: none;
    font-size: 13px;
    margin-left: 20px;
    color: #7d7f85;
    cursor: pointer;
}

.prod_img {
    /* background-image: url('https://smilingrocks.com/cdn/shop/products/Lab-grown-diamond-white-gold-ring-srr00363wht_11c94dae-c1d2-45e8-ae46-d16152c77f45_800x800.jpg?v=1613627318'); */
    width: 100%;
    min-height: 412px;
    height: 412px;
    background-size: cover;
    background-position: center;
    transition: all 0.7s ease;
}

.prod_imgFiletrHide {
    width: 100%;
    min-height: 412px;
    height: 500px;
    background-size: cover;
    background-position: center;
    transition: all 0.7s ease;
}

.prod_img2 {
    /* background-image: url('https://smilingrocks.com/cdn/shop/products/Lab-grown-diamond-white-gold-ring-srr00363wht_11c94dae-c1d2-45e8-ae46-d16152c77f45_800x800.jpg?v=1613627318'); */
    width: 100%;
    min-height: 412px;
    height: 600px;
    background-size: cover;
    background-position: center;
    transition: all 0.7s ease;
}

.prod_img2FiletrHider {
    width: 100%;
    min-height: 412px;
    height: 700px;
    background-size: cover;
    background-position: center;
    transition: all 0.7s ease;
}

.prod_img3 {
    /* background-image: url('https://smilingrocks.com/cdn/shop/products/Lab-grown-diamond-white-gold-ring-srr00363wht_11c94dae-c1d2-45e8-ae46-d16152c77f45_800x800.jpg?v=1613627318'); */
    width: 100%;
    min-height: 412px;
    height: 412px;
    background-size: cover;
    background-position: center;
    transition: all 0.7s ease;
}


.prod_img:hover {
    /* background-image: url('https://images.accentuate.io/?c_options=w_320,dpr_2.0&image=https://cdn.accentuate.io/3245609615460/5977841664100/SRR-00363WHT.jpg?2048x1950'); */
    transition: all 0.7s ease;
}


.smilingProductCategory {
    list-style: none;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease;
}

.smilingProductCategory.open {
    max-height: 200px;
    margin: 0;
}

.mobileSmlingProductFilterOverly {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: #4c4b47;
    z-index: 999;
    transition: all 0.9s ease-in-out;
}

.minmaxvalpl {
    outline: none !important;
    font-size: 13px !important;
    height: 25px !important;
    width: 30% !important;
    padding: 5px !important;
    color: #555050 !important;
}

.smilingProductDeatilTitleMobile {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
    min-height: 19.5px;
    width: 300px;
    margin: 3px;
}

.smilingProductDeatilTitleMobilenotWidth {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
    min-height: 19.5px;
    /* width:300px; */
    margin: 3px;
}

.productTitleLine {
    display: flex;
    justify-content: center;
    align-items: center;
}

.part {
    padding: 8px;
    display: flex;
    align-items: center;
}

.part-content {
    display: flex;
    color: #7b7b7b;
    font-size: 13.5px;
    font-family: "PT Sans", sans-serif;
    align-items: center;
    cursor: pointer;
    justify-content: end !important;
    gap: 12px;
}

.menuitemSelectoreMain {
    border: none;
    outline: none;
    font-size: 13px;
    color: rgb(66, 66, 66);
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    letter-spacing: 2.2px;
}

.menuitemSelectoreMainMobile{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: rgb(66, 66, 66);
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    letter-spacing: 2.2px;
}

.smilingAllProductDataMainMobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.smilingProductSubMain {
    justify-content: end;
}

.listing-image {
    position: relative;
}

.cart-icon {
    position: absolute!important;
    top: 15px;
    left: 10px;
    width: 30px;
    padding: 0px;
    min-width: 0px;
}

.wishlist-icon {
    position: absolute!important;
    top: 15px;
    right: 15px;
    width: 30px;
    padding: 0px;
    min-width: 0px;
}

@media screen and (min-width: 1440px) {
    .smilingMobileProductListSideBar {
        display: none;
    }

    .filterDivcontainer {
        display: flex !important;

    }
}


.smilingWebProductListSideBar {
    width: 20%;
    /* padding: 52px 0px 40px 100px; */
}

@media screen and (max-width: 1441px) {

    .smilingWebProductListSideBar {
        display: none;
    }

    .smilingFilterweb {
        display: none !important;
    }

    .smilingProductMain {
        margin-inline: 0%;
    }


    .smilingProductSubMain {
        display: flex;
        flex-direction: row;
    }

    .smilingProductImageMain {
        margin: 0px !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 800px) {

    .smilingProductImageBox {
        width: 50% !important;
    }
}


.instockP {
    border-radius: 0px 3px 0px 0 !important;
    font-weight: 300 !important;
    padding: 1px 3px !important;
    font-size: 8px !important;
    background: #c9f7cb !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    top: 28px !important;
    left: 0 !important;
    color: #3F51B5;
    right: unset !important;
    letter-spacing: unset !important;
    position: absolute;
}

@media screen and (max-width: 600px) {

    /* .prod_img{
        height: auto!important;
    } */

    .filterListMobileData {
        margin-inline: 0px !important;
    }

    .prod_img {
        min-height: 200px !important;
        height: 200px !important;
    }

    .productTitleLine p {
        font-size: 12px !important;
        white-space: unset !important;
        line-height: 1.4 !important;
        min-height: auto !important;
    }

    .thirdfilteDiv {
        display: none !important;
    }

    .mobileDeatilDiv1 {
        display: flex !important;
        flex-direction: column !important;
    }

    .mobileDeatilDiv2 {
        display: flex !important;
        flex-direction: column !important;
    }

    .smilingProductDeatilTitleMobile {
        white-space: normal !important;
    }
}

@media screen and (max-width: 500px) {

    .smilingAllProductDataMainMobile {
        display: flex !important;
        flex-direction: column !important;
        margin: 10px 0px;
    }

    .smilingProductImageBox {
        width: 100% !important;
    }

    .prod_img {
        width: 100% !important;
    }

}


.inputCheck:checked {
    background-color: '#7f7d85';
}

.text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    width: 100%;
    z-index: 1;
}

.text-container p {
    font-size: 14px;
    text-align: left;
    margin: 0px 0px 0px 40px;
}

.textContainerData {
    color: #AF8238;
    display: flex;
    justify-content: space-between;
}

.featherImage {
    width: 70px;
    height: 100px;
    margin-right: 30px;
}

.bg-image {
    position: relative;
    height: 140px;
    /* background-image: url("https://t4.ftcdn.net/jpg/02/92/56/91/360_F_292569116_Phht4uRj1YIuLFgBhrLu8171npBOcJcr.jpg"); */
    background-size: cover;
    background-position: center;
    /* z-index: -1; */
    /* margin: 25px 100px 22px 100px; */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d4dbe7;
    z-index: -1;
}

.filterDivcontainer {
    display: flex;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    justify-content: center;
    margin-block: 20px;
    /* margin: 0px 100px 0px 100px; */
}

.divider {
    width: 1px;
    background-color: #d9d9d9;
}


.select-button {
    width: 100%;
    color: #7b7b7b;
    font-size: 13.5px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.smilingAllProductDataMainMobileShow2Image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.main-ProdcutListConatiner {
    font-family: "PT Sans", sans-serif;
    width: 33%;
}

.main-ProdcutListConatiner2ImageShow {
    width: 49.5%;
}

.main-ProdcutListConatiner4ImageShow {
    width: 24.6%;
}

.smilingAllProductDataMainMobileShow4Image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}


.listing-card {
    height: auto;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
}


.selected-value {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.select-dropdown {
    position: absolute;
    list-style: none;
    background-color: #fff;
    border: 1px solid #caced1;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 10px;
    margin-top: 9.5px;
    max-height: 205px;
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}

.select-dropdown li {
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.select-dropdown li label {
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 1.5;
    font-size: 13px;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.select-dropdown li:hover,
.select-dropdown input:checked~label {
    background-color: #f2f2f2;
}

.custom-select.active .select-dropdown {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
}

.listing-details {
    padding: 5px 12px 0px 12px;
    /* min-height: 50px; */
    font-family: "PT Sans", sans-serif;
    overflow: hidden;
}

.productDetails {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: 36px; */
}

.listing4-details {
    padding: 0px;
}

.property-type {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.025em;
    opacity: 0.7;
    font-family: "PT Sans", sans-serif;
    color: #1f1919;
    height: 40px;
}

.property4-type {
    text-transform: uppercase;
    font-size: 11.5px;
    font-weight: 700;
    font-family: "PT Sans", sans-serif;
    letter-spacing: 0.025em;
    opacity: 0.7;
    color: #1f1919;
    height: 35px;
}

.listing-features {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 0px 0px;
    font-size: 11px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    color: #4a5568;
}

.listing-features4 {
    display: flex;
    justify-content: space-between;
    padding: 16px 5px 16px 2px;
    font-size: 14.5px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    color: #4a5568;
}

.filtercategoryLable {
    font-family: "PT Sans", sans-serif;
    font-size: 13px;
    font-weight: 500px !important;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.5;
    filter: contrast(10.4);
    color: #424242;
}

@media screen and (max-width: 1400px) {

    .paddingTopMobileSet.mainProduct {
        margin-inline: 1% !important;
    }
    .property-type{
        margin: 0px 0px 0px 10px!important;
    }
}

@media screen and (min-width: 776px) and (max-width: 1439px) {
    .filterDivcontainer {
        display: none !important;
    }

    .smilingProductSubMain {
        display: flex;
        flex-direction: column;
    }

    .smilingWebProductListSideBar {
        display: none !important;
    }

    .smilingProductImageMain {
        padding: 0px !important;
    }

    .main-ProdcutListConatiner {
        width: 33%;
    }

    .background-image-container {
        height: 290px;
    }

    .listing-details {
        padding: 0px 12px 0px 12px;
    }

    .smilingAllProductDataMainMobile {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        margin: 10px 0px 0px 0px;
        gap: 2px;
    }

    .smilingAllProductDataMainMobileShow2Image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
    }

    .main-ProdcutListConatiner2ImageShow {
        width: 49.5%;
    }

    .smilingProductMain {
        margin-inline: 0%;
    }

    .filterListMobileData {
        margin-inline: 0px !important;
    }

    .bg-image {
        margin: 10px 0px 10px 0px !important;
    }

    .smilingAllProductDataMainMobileShow4Image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
    }


    .listing-card {
        width: 100%;
    }

    .property-type {
        width: 100%;
        font-size: 0.875rem;
        font-weight: 700;
        /* height: 7vh; */
    }

    .price {
        font-size: 1.4rem;
    }

    .address {
        font-size: 0.95rem;
    }

    .listing-features {
        padding: 0px 7px 7px 7px;
        font-size: 11px;
    }

    .filterMobileDivcontainer {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .bg-image {
        margin: 10px 15px 10px 15px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .custom-select {
        width: 85px;
    }

    .select-dropdown {
        margin-top: 6px;
    }

    .part {
        padding: 5px;
        display: flex;
        align-items: center;
    }

    .firstfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .secondfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .part-content {
        display: flex;
        color: #7b7b7b;
        font-size: 12.5px;
        font-family: "PT Sans", sans-serif;
        align-items: center;
        justify-content: end !important;
    }

}


@media screen and (max-width: 775px) {

    .selected-value {
        gap: 5px !important;
    }

}

@media screen and (min-width: 426px) and (max-width: 775px) {
    .filterDivcontainer {
        display: none !important;
    }


    .smilingProductSubMain {
        display: flex;
        flex-direction: column;
    }

    .smilingWebProductListSideBar {
        display: none !important;
    }

    .main-ProdcutListConatiner {
        width: 49%;
    }

    .background-image-container {
        height: 290px;
    }

    .smilingAllProductDataMainMobile {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 2px;
        margin: 5px;
    }

    .smilingProductMain {
        margin-inline: 0%;
    }


    .listing-card {
        width: 100%;
    }

    .property-type {
        width: 100%;
        font-size: 0.875rem;
        font-weight: 700;
        height: 5vh;
    }

    .price {
        font-size: 1.4rem;
    }

    .address {
        font-size: 0.95rem;
    }

    .listing-features {
        padding: 7px;
        font-size: 13.5px;
    }

    .filterMobileDivcontainer {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .bg-image {
        margin: 10px 0px 10px 0px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .custom-select {
        width: 85px;
    }

    .select-dropdown {
        margin-top: 6px;
    }

    .part {
        padding: 5px;
        display: flex;
        align-items: center;
    }

    .firstfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .secondfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .part-content {
        display: flex;
        color: #7b7b7b;
        font-size: 12.5px;
        font-family: "PT Sans", sans-serif;
        align-items: center;
        justify-content: end !important;
    }
}

.inputCheck:checked {
    background-color: "#7f7d85";
}

@media screen and (min-width: 376px) and (max-width: 425px) {
    .filterDivcontainer {
        display: none !important;
    }

    .smilingProductSubMain {
        display: flex;
        flex-direction: column;
    }

    .smilingWebProductListSideBar {
        display: none !important;
    }

    .smilingAllProductDataMainMobile {
        gap: 2px;
    }

    .smilingProductImageMain {
        padding: 0px 2px 0px 2px;
    }

    .main-ProdcutListConatiner {
        width: 49.6%;
    }

    .background-image-container {
        height: 210px;
    }

    .listing-details {
        padding: 8px;
    }

    .smilingAllProductDataMainMobile {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
    }

    .smilingProductMain {
        margin-inline: 0%;
    }


    .listing-card {
        width: 100%;
    }

    .property-type {
        width: 100%;
        font-size: 0.675rem;
        font-weight: 700;
        height: 6vh;
    }

    .price {
        font-size: .75rem;
    }

    .address {
        font-size: 0.75rem;
    }

    .listing-features {
        padding: 5px;
        font-size: 10.5px;
    }

    .feature p {
        margin: 0 0 2px 5px;
    }

    .filterMobileDivcontainer {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        overflow: hidden;
    }

    .bg-image {
        margin: 10px 0px 10px 0px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .custom-select {
        width: 85px;
    }

    .select-dropdown {
        margin-top: 6px;
    }

    .part {
        padding: 5px;
        display: flex;
        align-items: center;
    }

    .firstfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .secondfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .part-content {
        display: flex;
        color: #7b7b7b;
        font-size: 12.5px;
        font-family: "PT Sans", sans-serif;
        align-items: center;
        justify-content: end !important;
    }
}

@media screen and (max-width: 375px) {
    .filterDivcontainer {
        display: none !important;
    }

    .smilingProductSubMain {
        display: flex;
        flex-direction: column;
    }

    .smilingWebProductListSideBar {
        display: none !important;
    }

    .smilingAllProductDataMainMobile {
        gap: 2px;
    }

    .cart-icon {
        position: absolute;
        top: 5px;
        left: 8px;
        width: 10px;
    }

    .smilingProductImageMain {
        padding: 10px 2px 0px 8px;
    }

    .main-ProdcutListConatiner {
        width: 49%;
    }

    .background-image-container {
        height: 160px;
    }

    .listing-details {
        padding: 8px;
    }

    .smilingAllProductDataMainMobile {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        margin: 0px;
    }

    .smilingProductMain {
        margin-inline: 0%;
    }

    /* .paddingTopMobileSet {
        padding-top: 1px !important;
        margin: 136px 0px 0px 0px !important;
    } */

    .listing-card {
        width: 100%;
    }

    .property-type {
        width: 100%;
        font-size: 0.575rem;
        font-weight: 700;
        height: 5vh;
        overflow: hidden;
    }

    .price {
        font-size: .75rem;
    }

    .address {
        font-size: 0.75rem;
    }

    .listing-features {
        padding: 5px;
        font-size: 10.5px;
    }

    .feature-count {
        font-weight: 700;
        color: #1f1919;
        opacity: .8;
    }

    .feature p {
        margin: 0 0 2px 5px;
    }

    .filterMobileDivcontainer {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .bg-image {
        margin: 0px 0px 10px 0px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .select-button {
        font-size: 12.5px;
    }

    .custom-select {
        width: 85px;
    }

    .select-dropdown {
        margin-top: 6px;
    }

    .part {
        padding: 5px;
        display: flex;
        align-items: center;
    }

    .firstfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
    }

    .secondfilteDiv {
        border-right: 0.01rem solid #d9d9d9 !important;
        height: 35px;
    }

    .cart-icon {
        position: absolute;
        top: 5px;
        left: 8px;
        width: 10px;
    }

    .wishlist-icon {
        position: absolute;
        top: 5px;
        right: 20px;
        width: 10px;
    }

    .menuitemSelectoreMain {
        font-size: 11px !important;
        font-family: "PT Sans", sans-serif !important;
        letter-spacing: 0px !important;
    }

    .menuitemSelectoreMainMobile {
        font-size: 11px !important;
        font-family: "PT Sans", sans-serif !important;
        letter-spacing: 0px !important;
    }

    
    .part-content {
        display: flex;
        color: #7b7b7b;
        font-size: 12.5px;
        font-family: "PT Sans", sans-serif;
        align-items: center;
        justify-content: end !important;
    }
}