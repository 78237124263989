
.gorHoroScopMain{
    margin-top: 160px;
}

.gotHoroMainTitle{
    font-size: 60px;
    text-align: center;
    font-family: 'Freight Big Pro,serif';
    margin: 0px;
}

.gorHoroScopeBoxMain{
    margin-inline: 16%;
}
.gorHoroBoxMain{
    display: flex;
    margin-top: 60px;
}
.gorHoroImgBox1{
    width: 50%;
}

.gorHoroDescBox2{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
}

.gorHoroBoxImg{
    width: 100%;
}

.gor-Horoscrollable-container {
    width: 100%;
    /* height: 700px; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* border: 1px solid #ccc; */
    padding: 10px;
}
.gor-image-Horoscroll-content {
    display: inline-block;
    overflow: hidden;
    object-fit: cover;
}

.gor-Horoimage-wrapper {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    width: 33.33%;
}

.gor-Horoimage-wrapper img {
    max-width: 100%;
    height: 500px;
    object-fit: cover;
}