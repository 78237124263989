#outlined-password-input::-ms-reveal {
    display: none !important;
}

#outlined-confirm-password-input::-ms-reveal {
    display: none !important;
}

.ForgotPassBtn {
    height: 40px;
    width: 32%;
    margin-inline: 15px;
    border: 1px solid black;
    margin-top: 10px;
    font-size: 14.5px;
    color: #1f1919;
    opacity: .8;
    font-weight: 600 !important;
    letter-spacing: 1px;
    transition: all 0.5s ease;
    font-family: "PT Sans", sans-serif;
    cursor: pointer;
    background-color: white;
}

.ForgotPassBtn:hover {
    background-color: rgba(66, 66, 66, 0.05);
}


@media screen and (max-width: 700px) {

    .ForgotPassBtn {
        width: 80%;
    }
 
}