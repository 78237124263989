.css-1wc848c-MuiFormHelperText-root {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.smling-forgot-main {    
    background-color: white;
    width: 30%;
}
.smling-forgot-main-Color{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6% 0px 6% 0px;
}

.labGrowForgot {
    width: 430px;
    margin: 10px;
}

.submitBtnForgot {
    height: 40px;
    width: 430px;
    border: 1px solid black;
    margin-top: 10px;
    font-size: 14px;
    color: #424242;
    font-weight: 600!important;
    letter-spacing: 1px;
    transition: all 0.5s ease;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer;
    background-color: white;
}
.submitBtnForgot:hover{
    background-color: rgba(66, 66, 66, 0.05);
}
.cancleForgot {
    color: #7d7f85;
    font-size: 12px;
    margin-top: 25px;
    font-weight: 500;
    letter-spacing: 1px;

    position: relative;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
}

.cancleForgot::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.3s ease;
}

.cancleForgot:hover::before {
    width: 100%;
}

.cancleBtn{
    margin-top: 10px;
}

@media screen and (max-width: 1500px) {
    .smling-forgot-main{
        width: 50%;
    }
}

@media screen and (max-width: 950px) {
    .AuthScreenMainTitle {
        font-size: 25px !important;
        padding-bottom: 50px !important;
    }

    .AuthScreenSubTitle {
        font-size: 12px !important;
        margin-inline: 15%;
    }

    .smling-forgot-main {
        margin-inline: 2%!important;
    }


}
@media screen and (max-width: 1200px) {

    /* .smling-forgot-main-Color{
        padding-top: 20%;
    } */
    .smling-forgot-main{
        width: 70%;
    }
}

@media screen and (max-width: 950px) {
    .AuthScreenMainTitle {
        font-size: 25px !important;
        padding-bottom: 50px !important;
    }

    .AuthScreenSubTitle {
        font-size: 12px !important;
        margin-inline: 15%;
    }

    .smling-forgot-main {
        margin-inline: 2% !important;
    }


}

@media screen and (max-width: 700px) {
    .labGrowForgot {
        width: 90%;
    }

 
    .submitBtnForgot {
        width: 90%!important;
        height: 50px !important;

    }
   
    /* .smling-forgot-main-Color {
        padding-top: 30%;
    } */

    .smling-forgot-main {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {

    .submitBtnForgot {
        width: 90%;
        height: 40px !important;
    }

    .AuthScreenSubTitle{
        margin-top: -65px!important;
    }
}