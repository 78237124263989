.gorAboutMain {
    margin-top: 100px;
}

.gorAboutTopImageWeb {
    width: 100%;
}

.gorAboutBoxDivMain {
    margin-inline: 15%;
}

.gorAboutBoxMain {
    display: flex;
    margin-block: 50px;
}

.gorAboutBox1 {
    width: 50%;
}

.gorAboutBox1Image {
    width: 100%;
    padding: 10px;
}

.gorAboutBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5%;
}

.gorAboutOtheimgMain1 {
    width: 50%;
}

.gorAboutOtheimgMain1Img {
    width: 100%;
}

.gorAboutOtheimgMain2 {
    width: 50%;
    padding: 50px;
}

.gorAboutOtheimgMain2Img {
    width: 100%;
}


.gorAbout-scrollable-container {
    width: 100%;
    /* height: 800px; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* border: 1px solid #ccc; */
    padding: 10px;
}

.gorAbout-image-scroll-content {
    display: flex;
    overflow: hidden;
    object-fit: cover;
}

.gorAbout-image-wrapper {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    width: 750px;
}


@media screen and (max-width: 950px) {

    .gorAboutBoxDivMain{
    margin-inline: 2%;

    }
    .gorAboutBoxMain{
        display: flex;
        flex-direction: column;
    }

    .gorAboutBox1{
        width: 100%;
    }
    .gorAboutBox2{
        width: 100%;
    }

    .gorAboutMainTitle{
        font-size: 30px!important;
        text-align: center!important;
    }
    .gotAboutMainDesc{
        width: 100%!important;
    }

    .gorAboutFooterIamge{
        width: 100%;
    }

    .gorGivenDesc{
        width: 100%!important;
    }
}

@media screen and (min-width:751px) {
    .gorAboutTopImageMobile {
        display: none;
    }
}

@media screen and (max-width:750px) {

    .gorAboutTopImageWeb {
        display: none;
    }

    .gorAboutTopImageMobile{
        width: 100%;
    }
}