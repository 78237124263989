.salesPagePagBtn:has(.muiSmilingRocksBtn) {
    padding-bottom: 20px !important;
    margin-top: -3px;
}

.salePageBtnAlign {
    align-items: baseline !important;
    margin-top: 0 !important;
}

@media(max-width: 768px) {
    .salesApiSection {
        padding: 15px 7px !important;
    }

    .salesPagePagBtn {
        padding-bottom: 20px !important;
    }


    .salesApiTable .MuiTablePagination-root {
        padding-bottom: 0 !important;
        max-height: 50px;
    }

    .salesApiTable .MuiTablePagination-root .MuiTablePagination-toolbar {
        padding: 0 10px;
    }

    .salesApiTable .MuiTablePagination-root .MuiInputBase-colorPrimary.MuiTablePagination-input {
        margin-right: 5px;
    }

    .salesApiTable .MuiTablePagination-root .MuiInputBase-colorPrimary.MuiTablePagination-input div[role="combobox"] {
        padding-left: 0;
        margin-left: -5px;
    }

    .salesApiTable .MuiTablePagination-root .MuiTablePagination-actions {
        margin-left: 5px;
    }

    .salesApiTable .MuiTablePagination-root .MuiTablePagination-actions button {
        padding-right: 0;
    }

}

@media(max-width: 576px) {

    .salesPartTable {
        display: grid;
        flex-direction: column;
        min-width: max-content;
        width: 100%;
    }

    div[role="tabpanel"]>div.MuiBox-root:has(.salesApiSection) {
        padding-left: 0;
        padding-right: 0;
    }

    .salesApiTable table thead tr {
        display: flex;
        flex-direction: column;
    }

    .salesApiTable>div>table {
        min-width: 100%;
        display: flex;
        position: static !important;
        flex-direction: column;
        border: unset;
    }

    .salesApiTable>div>table>thead>tr>th,
    .salesApiTable>div>table>tbody>tr>td {
        text-align: start !important;
        padding: 5px 10px !important;
    }

    .salesApiTable>div>table>thead>tr>th svg {
        position: absolute;
        right: -30px;
    }

    .salesApiTable>div>table>tbody>tr {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .salesApiTable>div>table>tbody>tr>td:last-child {
        border-bottom: 1px solid #9b978f !important;
    }

    .salesApiTable>div>table>tbody>tr>td {
        min-height: 31.02px;
    }

    .salesApiTable>div>table>tbody>tr:first-child>td:first-child {
        border-top: 1px solid #9b978f !important;
    }

    .salesApiTable>div>table>thead>tr>th:last-child,
    .salesApiTable>div>table>thead>tr>th:first-child,
    .salesApiTable>div>table>thead>tr {
        border-radius: 0 !important;
    }

    .quotationJobSec {
        max-height: 450px !important;
    }

}