.css-1aquho2-MuiTabs-indicator{
    bottom: 10px;
    height: 100px;
}

.accountMain {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: center;
    margin: 0px;

}

.title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
}

.newPassword{
    height: 30px;
    width: 85%;

}

.oldPassword{
    height: 80px;
    width: 85%;

}

.tab-container{
    width:50%
}

@media screen and (max-width:768px) {
    .tab-container{
        width:95%
    } 

    .newPassword{
        height: 30px;
        width: 100%;
    }
    
    .oldPassword{
        height: 80px;
        width: 100%;
    }
}
