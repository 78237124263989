.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: black !important;
}

.css-1x5jdmq.Mui-disabled {
    -webkit-text-fill-color: black !important;
}
.SmilingAddEditAddrwess {
    height: 40px;
    width: 160px;
    font-family: "PT Sans", sans-serif !important;
    font-weight: 700 !important;
    opacity: .9;
    background: #f6efe6 !important;
    color: rgba(31, 25, 25, 0.7) !important;
    border: none;
    outline: none;
    font-weight: 500;
}
.SmilingAddEditAddrwess:hover{
    background-color: #fff !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



@media screen and (max-width: 1000px) {

    .mobileEditProfileDiv{
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
    }
}
    @media screen and (max-width: 500px) {

    .smilingEditProfilePopup {
        width: 95% !important;
        margin: 5px !important;
    }
}