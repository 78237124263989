.skeltonMainDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    margin-inline: 13%;
    margin-bottom: 20px;
}

.skeltoncards{
    box-shadow: none !important;
}

@media (max-width:778px) {
    .filtercards{
        display: none;
    }
}