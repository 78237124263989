.checkout__header {
    background: white;
    border-bottom: 1px solid #f6f6f6;
    background-color: #f2f2f2;
    padding: 1.5rem 0 1rem;
    margin-bottom: 1rem;
}

header {
    box-shadow: 0 0 25px 0 rgba(200, 200, 200, .5);
}

header {
    font-family: "Lato", Arial, sans-serif;
    text-transform: uppercase;
    line-spacing: 1px;
}

.paymentMain {
    display: flex;
    justify-content: center;
}

.topBar {
    width: 60%;
    /* border: 1px solid black; */
}

.radioButton {
    font-size: 18px;
    font-weight: 400;
    margin: 7px;
}



.pay_button {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    max-width: 500px;
    background-color: #45d4d5;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    font-weight: 300;
    border: none;
    cursor: pointer;
}

.cardDetails{
    display: flex;
    order: 1;
    z-index: -1;
}

.paymentCart{
    min-height: 170px;
    /* padding: 0.8em; */
    background: #fefefe;
    position: relative;
    overflow: visible;
    border-bottom: 1px solid rgb(233, 233, 233);
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    /* margin: 10px; */
}
.mainPaymentCart{
    border: 1px solid #D5D9D9;
    border-radius: 10px;

}


@media screen and (min-width: 1441px) {
    .mobileMainPaymentCart {
        display: none;
    }
}

@media screen and (max-width: 320px) {

    .mainPaymentCart{
        display: none;
    }

    .mobileMainPaymentCart{
    /* border: 1px solid #D5D9D9; */

    }
    .paymentMain{
        display: 'block'!important;
    }

    .mobileCartPayment{

    }

    .mobilePaymentCart{
        margin: 10px;
        width: 95%;
        background: #fefefe;
        border: 1px solid rgb(233, 233, 233);
        display: flex;
        flex-direction: row;
    }

    .mobilePaymentCartImage{
        height: 152px;
        width: 140px;
        transition: .3s ease;
    }

    .topBar{
        width: 100%!important;
    }
    .mobilePaymentTopBar{
        display: flex!important;
        flex-direction: column!important;
    }
}

@media screen and (max-width: 375px) {

    .mainPaymentCart{
        display: none;
    }

    .mobileMainPaymentCart{
    /* border: 1px solid #D5D9D9; */

    }
    .paymentMain{
        display: 'block'!important;
    }

    .mobileCartPayment{

    }

    .mobilePaymentCart{
        margin: 10px;
        width: 95%;
        background: #fefefe;
        border: 1px solid rgb(233, 233, 233);
        display: flex;
        flex-direction: row;
    }

    .mobilePaymentCartImage{
        height: 152px;
        width: 140px;
        transition: .3s ease;
    }

    .topBar{
        width: 100%!important;
    }
    .mobilePaymentTopBar{
        display: flex!important;
        flex-direction: column!important;
    }
}

@media screen and (max-width: 425px) {

    .mainPaymentCart{
        display: none;
    }

    .mobileMainPaymentCart{
    /* border: 1px solid #D5D9D9; */

    }
    .paymentMain{
        display: 'block'!important;
    }

    .mobileCartPayment{

    }

    .mobilePaymentCart{
        margin: 10px;
        width: 95%;
        background: #fefefe;
        border: 1px solid rgb(233, 233, 233);
        display: flex;
        flex-direction: row;
    }

    .mobilePaymentCartImage{
        height: 152px;
        width: 140px;
        transition: .3s ease;
    }

    .topBar{
        width: 100%!important;
    }
    .mobilePaymentTopBar{
        display: flex!important;
        flex-direction: column!important;
    }
}

@media screen and (max-width: 770px) {

    .mainPaymentCart{
        display: none;
    }

    .mobileMainPaymentCart{
    /* border: 1px solid #D5D9D9; */

    }
    .paymentMain{
        display: 'block'!important;
    }

    .mobileCartPayment{

    }

    .mobilePaymentCart{
        margin: 10px;
        width: 95%;
        background: #fefefe;
        border: 1px solid rgb(233, 233, 233);
        display: flex;
        flex-direction: row;
    }

    .mobilePaymentCartImage{
        height: 152px;
        width: 140px;
        transition: .3s ease;
    }

    .topBar{
        width: 100%!important;
    }
    .mobilePaymentTopBar{
        display: flex!important;
        flex-direction: column!important;
    }
}
@media screen and (max-width: 1024px) {

    .mainPaymentCart{
        display: none;
    }

    .mobileMainPaymentCart{
    /* border: 1px solid #D5D9D9; */

    }
    .paymentMain{
        display: 'block'!important;
    }

    .mobileCartPayment{

    }

    .mobilePaymentCart{
        margin: 10px;
        width: 95%;
        background: #fefefe;
        border: 1px solid rgb(233, 233, 233);
        display: flex;
        flex-direction: row;
    }

    .mobilePaymentCartImage{
        height: 152px;
        width: 140px;
        transition: .3s ease;
    }

    .topBar{
        width: 100%!important;
    }
    .mobilePaymentTopBar{
        display: flex!important;
        flex-direction: column!important;
    }
}
@media screen and (max-width: 1440px) {

    .mainPaymentCart{
        display: none;
    }

    .mobileMainPaymentCart{
    /* border: 1px solid #D5D9D9; */

    }
    .paymentMain{
        display: 'block'!important;
    }

    .mobileCartPayment{

    }

    .mobilePaymentCart{
        margin: 10px;
        width: 95%;
        background: #fefefe;
        border: 1px solid rgb(233, 233, 233);
        display: flex;
        flex-direction: row;
    }

    .mobilePaymentCartImage{
        height: 152px;
        width: 140px;
        transition: .3s ease;
    }

    .topBar{
        width: 100%!important;
    }
    .mobilePaymentTopBar{
        display: flex!important;
        flex-direction: column!important;
    }
}

