.fs_al{
    font-size: 15px;
}
.fs_al2{
    font-size: 14px;
}
.eeal{
    font-size: 12px;
    width: 70%;
}
.fs_td{
    font-size: 13px;
}
._centeral{
    display: flex;
    justify-content: center;
    align-items: center;
}
.w_Al{
    width: 7.14%;
}
.daybtn{
    /* height: 35px;
    width: 35px; */
    min-height: 42px;
    max-height: 42px;
    min-width: 45px;
    padding: 5px !important;
}
.daybtn:hover{
    background-color: rgb(210, 213, 213);
}
.selected {
    border: 1px solid darkgray !important;
}
.toggleBtn{
    border: none;
    font-size: 14px;
    background-color: rgb(125, 127, 133);
    color: white !important;
    font-weight: 500;
    min-width: 100px;
    width: 100%;
    outline: none !important;
    border-radius: 4px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
}

.bal_Amt_ac{
    padding: 8px;
    border: 1px solid #989898;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
}
/* .header_al{
    display: none;
} */

/* @media print {
    .header_al{
        display: normal;
    }   
    
} */
.ledger_title{
    /* height: 40px; */
    margin: 1rem;
    background-color: rgb(125, 127, 133);
    color: white !important;
    padding: 1rem;
    margin-left: 0px;
    margin-right: 0px;
}
@media (max-width:576px){
    .fs_Al_mq *{
        font-size: 11px !important;
    }
    .fs_Al_mq{
        
        padding-left: 0.65rem !important;
    }
    .flex_col_Al{
        flex-direction: column !important;
        align-items: start !important;
    }
    .px_2_al{
        padding: 0 0.3rem !important;
    }
}