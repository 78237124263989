
.gorContactMain{
    margin-top: 11%;
    margin-inline: 30%;
}

.gorContacInputBox{
    height: 50px;
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    padding-left: 20px;
}

.gorContacInputMessageBox{
    height: 150px;
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    padding-left: 20px;
    appearance: none; /* Remove default arrow on other browsers */
    
}

.gorContactSubBtn{
    height: 50px;
    width: 150px;
    color: white;
    background-color: #4d5469;
}

@media screen and (max-width: 1440px) {

    .gorContactMain{
        margin-inline: 15%;
    }
}

@media screen and (max-width: 1000px) {

    .gorContactMain{
        margin-inline: 15%;
        margin-top: 13%;
    }
}

@media screen and (max-width: 700px) {

    .gorContactMain{
        margin-inline: 5%;
        margin-top: 18%;
    }
}

@media screen and (max-width: 500px) {

    .gorContactMain{
        margin-inline: 5%;
        margin-top: 35%;
    }
}